import React from 'react'
import './Course.css'
import { MDBBtn } from 'mdb-react-ui-kit'
import Confetti from '../Lotties/Confetti'
import cheerup from '../Lotties/cheer-up.json'
import { useLottie } from 'lottie-react'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { color, display } from '@mui/system'
const { useState, useEffect, Fragment } = React

const Question = ({ question, setAnswerStatus }) => {
  
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null)
  useEffect(() => {}, [])
  useEffect(() => {
    if (selectedAnswerIndex != null) {
      setAnswerStatus(selectedAnswerIndex === question.correctAnswerIndex)
    }
  }, [selectedAnswerIndex])

  useEffect(() => {
    setSelectedAnswerIndex(null)
  }, [question])

  const getClasses = (index) => {
    let classes = []
    if (selectedAnswerIndex != null) {
      if (
        index !== question.correctAnswerIndex &&
        selectedAnswerIndex === index
      ) {
        classes.push('incorrect')
      }
      if (selectedAnswerIndex === index) {
        classes.push('selected')
      }
      if (index === question.correctAnswerIndex) {
        if (selectedAnswerIndex === index) {
          classes.push('correct')
        }
        // else {
        //   classes.push("incorrect")
        // }
      }
    }

    return classes.join(' ')
  }

  return (
    <div className="question">
      <div className="questionText">
        {question !== null ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {question.question !== null && question.question !== '' ? (
              <div
                style={{
                  paddingRight: '4px',
                  fontFamily: 'Playfair Display',
                  color: 'white',
                }}
              >
                {question.question}
              </div>
            ) : (
              ''
            )}
            {question.questionImage !== null &&
            question.questionImage !== '' ? (
              <img
                src={`http://localhost:3860/static/Images/${question.questionImage}`}
                height="100px"
                width="100px" alt=""
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          <img
            src={`http://localhost:3860/static/Images/${question.questionImage}`}
            height="100px"
            width="100px" alt=""
          />
        )}
      </div>

      <div className="answers">
        {question.answers.length > 0
          ?( question.answers.map((answer, index) => {
              //console.log(question)
              return answer === null ? (
                ''
              ) : (
                <div
                  key={index}
                  className={`answer ${getClasses(index)}`}
                  onClick={() =>
                    selectedAnswerIndex == null && setSelectedAnswerIndex(index)
                  }
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <div style={{ paddingRight: '20px' }}>{answer}</div>
                  <div style={{ padding: '0px' }}>
                    {question.answersImage !== null ? (
                      <img
                        src={`http://localhost:3860/static/Images/${question.answersImage[index]}`}
                        height="50px"
                        width="50px" alt=""
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )
            }))
          : (question.answersImage.length > 0 ? (question.answersImage.map((answer, index) => {
              //console.log(question)
              return (
                <div
                  key={index}
                  className={`answer ${getClasses(index)}`}
                  onClick={() =>
                    selectedAnswerIndex == null && setSelectedAnswerIndex(index)
                  }
                >
                  {question.answers !== null
                    ? question.answers.map((answer, index) => {
                        //console.log(answer)
                        ;<div>{answer}</div>
                      })
                    : ''}
                  {answer !== null ? (
                    <img
                      src={`http://localhost:3860/static/Images/${answer}`}
                      height="30px"
                      width="30px" alt=""
                    />
                  ) : (
                    'I'
                  )}
                </div>
              )
            })) : ('')
            )}

        {/* {question.answers.map((answer, index) => {
          console.log(question)
          return (
            <div
              key={index}
              className={`answer ${getClasses(index)}`}
              onClick={() =>
                selectedAnswerIndex == null && setSelectedAnswerIndex(index)
              }
            >
              {answer}
            </div>
          )
        })} */}
      </div>
    </div>
  )
}

const ProgressBar = ({ currentQuestionIndex, totalQuestionsCount }) => {
  const progressPercentage = (currentQuestionIndex / totalQuestionsCount) * 100

  return (
    <div className="progressBar">
      <div className="text">
        {currentQuestionIndex} answered (
        {totalQuestionsCount - currentQuestionIndex} remaining)
      </div>
      <div className="inner" style={{ width: `${progressPercentage}%` }} />
    </div>
  )
}

const Quiz = ({ questions, parentCallback }) => {
  const [questionIndex, setQuestionIndex] = useState(null)
  const [answerStatus, setAnswerStatus] = useState(null)
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0)
  const [quizComplete, setQuizComplete] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const history = useHistory()
  useEffect(() => {
    setAnswerStatus(null)
  }, [questionIndex])

  useEffect(() => {
    if (answerStatus) {
      setCorrectAnswerCount((count) => count + 1)
    }
  }, [answerStatus])
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: cheerup,

      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  const notify = () => {
    toast.success(
      <div>
        <Coin />
        {/* {'You have successfully completed the topic'} */}
      </div>,
      {
        toastId: 'success1',
        autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      },
    )
    setTimeout(navigate, 5000)
    // props.history.replace("/Course");
  }
  const navigate = () => {
    history.push('/Course')
  }
  const onNextClick = () => {
    if (questionIndex === questions.length - 1) {
      setQuizComplete(true)
      parentCallback(true)
      //console.log('setShowConfetti')
      setShowConfetti(true)
      if (correctAnswerCount >= questions.length) {
        notify()
      }
    } else {
      setQuestionIndex(questionIndex == null ? 0 : questionIndex + 1)
    }
  }

  const onRestartClick = () => {
    setQuizComplete(false)
    setQuestionIndex(null)
    setCorrectAnswerCount(0)
    setTimeout(() => {
      setShowConfetti(false)
    }, 6000)
  }

  if (questionIndex == null) {
    return (
      <div className="quiz">
        <h2 style={{ color: 'white' }}>Try the Quiz to win more points</h2>
        {/* <button className="start" onClick={onNextClick}>Start</button> */}
        <div style={{ padding: '10px' }}>
          <MDBBtn
            size="sm"
            onClick={onNextClick} 
            style={{
              backgroundColor: '#1F594A',
              Width: '20px',
              letterSpacing: '1.5px',
            }}>
            Start
          </MDBBtn>
        </div>
      </div>
    )
  }

  return (
    <div className="quiz">
      {quizComplete ? (
        <Fragment>
          <h1 style={{ color: 'white' }}>Quiz complete!</h1>
          <h4 style={{ color: 'white' }}>
            You answered {correctAnswerCount} questions correctly (out of{' '}
            {questions.length} questions)
          </h4>
          {correctAnswerCount >= questions.length && (
            <Confetti animationDone={showConfetti} />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {/* <ProgressBar currentQuestionIndex={questionIndex} totalQuestionsCount={questions.length} /> */}
          <Question
            question={questions[questionIndex]}
            setAnswerStatus={setAnswerStatus}
          />
          {answerStatus != null && (
            <div>
              {/* <div className="answerStatus" style={{ color: 'white' }}>
                {!!answerStatus
                  ? 'Correct! :)'
                  : 'Your answer was incorrect :('}
              </div> */}
              {/* <button className="next" onClick={onNextClick}> */}
              <div
              //  style={{ padding: "10px" }}
              >
                <MDBBtn
                  size="sm"
                  onClick={onNextClick} style={{
                    backgroundColor: '#1F594A',
                    Width: '20px',
                    letterSpacing: '1.5px',
                  }}>
                  {questionIndex === questions.length - 1
                    ? 'See results of this quiz'
                    : 'Next Question ->'}
                  {/* </button> */}
                </MDBBtn>
              </div>
            </div>
          )}
        </Fragment>
      )}
      {
        questionIndex != null && (
          <div style={{ paddingTop: '10px' }}>
            <MDBBtn
              size="sm"
              onClick={onRestartClick} style={{
                backgroundColor: '#1F594A',
                Width: '20px',
                letterSpacing: '1.5px',
              }}
            >
              Restart Quiz
            </MDBBtn>
          </div>
        )
        //   <button className="restart" onClick={onRestartClick}>Restart quiz</button>
      }
    </div>
  )

  // else
  // {
  //   return(<div></div>)
  // }
}
export default Quiz

import React from 'react'
import Navbar from './navbar.js'
import './Course.css'
import Image from '../../assets/Background.png'
import Axios from 'axios'
import { useState, useEffect } from 'react'
import PDFViewer from 'pdf-viewer-reactjs'
import { useLottie } from 'lottie-react'
import { useLocation } from 'react-router-dom'
import animationData from '../Lotties/SuccessLottie.json'
//import SuccessLottie from "../Lotties/SuccessLottie";
import coins from '../Lotties/coins.json'
import thumbsup from '../Lotties/thumbsup.json'
import Quiz from './Quiz'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './footer'
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBFooter,
  MDBInputGroup,
  MDBDropdownDivider,
} from 'mdb-react-ui-kit'

toast.configure()
function ViewLesson(props) {
  const location = useLocation()
  const [CourseContent, _setCourseContent] = useState([])

  const [percentage, setPercentage] = useState()
  // const [percentageview, setpercentageview] = useState();
  // const [Watchcount, setWatchcount] = useState();
  // const [Scorecount, setScorecount] = useState();
  // const [Contenttype, setContenttype] = useState();

  const [Coins, setCoins] = useState(false)
  const [showSuccessLottie, _setShowSuccessLottie] = useState(false)
  const lottieRef = React.useRef(showSuccessLottie)
  const CourseContentRef = React.useRef(CourseContent) //
  const UserId = sessionStorage.getItem('userid')
  var divVideoPlayer = document.getElementById('videoPlayer')
  let course_ID = sessionStorage.getItem('courseId')
  let course_Title = sessionStorage.getItem('courseTitle')
  //console.log(CourseContent)
  const [basicModal, setBasicModal] = useState(false)
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData,

      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  const notify = () => {
    toast.success(
      <div>
        <Coin />
        {'You have successfully completed the topic'}
      </div>,
      {
        toastId: 'success1',
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_RIGHT,
      },
    )
    setTimeout(navigate, 5000)

  }
  const navigate = () => {
    props.history.replace('/Student', (props = { Coins }))
  }
  
  const setCourseContent = (data) => {
    CourseContentRef.current = data
    _setCourseContent(data)
  }



  const setShowSuccessLottie = (isVisible) => {
    let lDiv = document.getElementById('succ-lot')

    if (isVisible) {
      lDiv.style.zIndex = '10'
    } else {
      lDiv.style.zIndex = '-1'
    }
    lottieRef.current = isVisible
    _setShowSuccessLottie(isVisible)
  }

  useEffect(() => {
    let cObj = sessionStorage.getItem('courseObj')
    let obj = JSON.parse(cObj)
    setPercentage(0)
    setShowSuccessLottie(false) 
    Axios.get(`/user/ViewBook/${obj.COURSE_ID}`).then((response) => {
      //console.log(response.data)
      let contentEle = response.data.recordset[0]
      contentEle.PERCENTAGE_VIEWED =
        (contentEle.CURRENT_PROGRESS / contentEle.CONTENT_VIDEO_DURATION) * 100
      setCourseContent(contentEle)
      //if (contentEle.IS_FREE_PREVIEW === 1) {
        setShowSuccessLottie(false)
        if (contentEle.CONTENT_TYPE === 1) {
          document.getElementById('videoCart').style.display = 'block'
          if (document.getElementById('videoCart').onplay)
            document
              .getElementById('videoCart')
              .removeEventListener('play', started)
              if (document.getElementById('videoCart').onended)
              document
                .getElementById('videoCart')
                .removeEventListener('ended', finished)  
          let filepath =
            contentEle.CONTENT_VIDEO.substring(0, 5) === 'https'
              ? contentEle.CONTENT_VIDEO
              : `/static/videos/${contentEle.CONTENT_VIDEO}#t=${contentEle.CURRENT_PROGRESS}`
          document.getElementById('videoCart').setAttribute('src', filepath)
          document
            .getElementById('videoCart')
            .setAttribute('preload', 'metadata')
          document
            .getElementById('videoCart')
            .addEventListener('play', started, { passive: true })
            document
            .getElementById('videoCart')
            .addEventListener('ended', finished, { passive: true })
            document
            .getElementById('videoCart').addEventListener("contextmenu", (event) => {
              event.preventDefault();
            });
          document.getElementById('videoCart').style.display = 'block'
          document.getElementById('videoCart').contentId = contentEle.CONTENT_ID
          document.getElementById('videoCart').parentId = contentEle.PARENT_ID
          document.getElementById('videoCart').load()
          //vidEle.play()
          //}
        } 
        //v.load();
      // } else {
      //   document.getElementById('videoCart').setAttribute('src', '')
      //   divVideoPlayer.style.display = 'none'
      //   document.getElementById('quizViewer').style.display = 'none'
      //   // document.getElementById("docViewer").style.display = "none";
      //   setBasicModal(true)
      // }
      
    })
  }, [])

  const started = (event) => {
    if (event.target.contentId) {
      //console.log(`started--contentId- ${event.target.contentId}`);
    }
  }
  const finished = (event) => {
    setShowSuccessLottie(true)
    notify()
    //;<ToastContainer />
    //}
    //toast.success("You have successfully completed the topic", { autoClose: 4000 });

    if (event.target.contentId) {
      if (event.target.currentTime === event.target.duration) {
       
      }
    }
    setTimeout(() => {
      setShowSuccessLottie(false)
    }, 2000)
  }
  const LessonCompletedLottie = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <>
      <div id="succ-lot" className="lottie-wrapper"></div>
      <div
        style={{
          fontFamily: 'Playfair Display',
        }}
      >

          <Navbar />

            <MDBRow  style={{ margin: '15px' }}>
              <MDBCol size="12" className="float-start">
                <a href={`/Student`}>
                  <MDBIcon
                    size="lg"
                    className="float-start"
                    style={{
                      color: '#1F594A',
                      paddingLeft: '10px',
                      paddingTop: '10px',
                      paddingRight: '10px',
                    }}
                    icon="arrow-circle-left"
                  />{' '}
                </a>
                <p
                  style={{
                    color: '#1F594A',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingLeft: '10px',
                    paddingTop: '5px',
                  }}
                >
                  {CourseContent.CONTENT_TITLE}
                </p>
              </MDBCol>
            </MDBRow>

        <MDBRow style={{ height: '400px' }} className="gradient">
            <MDBCol
              size="6"
              style={{
                paddingLeft: '150px',
                paddingTop: '50px',
                color: 'white',
                height: '381px',
              }}
            >
              <h3> {CourseContent.CONTENT_TITLE}</h3>
              <h5>{CourseContent.CONTENT_SHORT_DESCRIPTION}</h5>
            </MDBCol>
            <MDBCol  className="float-end"
              id="videoPlayer"
              size="6"
              style={{
                height: '381px',
                width: '430px',
                display: 'block',
              }}
            >

              <video
                id="videoCart"
                controls
                autoPlay
                src={
                  CourseContent.CONTENT_VIDEO !== null &&
                  CourseContent.CONTENT_VIDEO !== undefined &&
                  CourseContent.CONTENT_VIDEO.substring(0, 5) === 'https'
                    ? CourseContent.CONTENT_VIDEO
                    : `/static/videos/${CourseContent.CONTENT_VIDEO}`
                }
                controlsList="nodownload"
                style={{
                  height: '400px',
                  // display: "block",
                  zIndex: '999', right:'0'
                  // width: "400px",
                  // verticalAlign: "middle",
                  // padding: "1px",
                }}
              ></video>
             
            </MDBCol>
          </MDBRow>

      

      </div>
     </>
  )
}

export default ViewLesson

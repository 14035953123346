import React, { useState } from 'react'
import Webcam from 'react-webcam'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'
import { MDBBtn } from 'mdb-react-ui-kit'
import { Camera } from '@mui/icons-material'
//const WebcamComponent = () => <Webcam />

const videoConstraints = {
  width: ' 120',
  height: '100',
  facingMode: 'user',
}
const WebcamCapture = (props) => {
  const [image, setImage] = useState('')
  const userId = sessionStorage.getItem('userid')
  const [cam, setcam] = useState(false)
  //console.log(props)
  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(image);

    const profile = new FormData()
    // console.log(profile);
    if(image!== null && image !== '' && image !== undefined){
    profile.append('myImage', `${image}`)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      mode: 'no-cors',
    }
    Axios.post(
      `/user/uploadim/${userId}`,
      // `http://localhost:9000/upload/${Mobile}`,
      profile,
      config,
    )
      .then(() => {
        // alert("Your profile picture is uploaded");
        window.location.reload(false)
        // console.log(profile);
      })
      .catch((error) => {
        console.log(error)
      })
    }else{
      toast.warning(<div>{'Please Enable Camera and Capture Image'}</div>, {
        toastId: 'success1',
        autoClose: 2000,
        position: toast.POSITION.TOP_CENTER,
      })  
    }
  }

  const webcamRef = React.useRef(null)
  // console.log(`${image}`);

  const capture = () => {
    // const imagesrc = webcamRef.current.getScreenshot();
    setcam(true)
    const getScreenshot = webcamRef.current.getScreenshot()
    // console.log(getScreenshot);

    // console.log("file");

    // console.log("hello", userId, image);

    setImage(getScreenshot)
  }
  const Camera = () => {
    setcam(true)
  }
  return (
    <div className="webcam-container">
      <div className="webcam-img pb-3">
        {image === '' ? (
          <Webcam
            audio={false}
            height={210}
            ref={webcamRef}
            screenshotFormat="image/png"
            width={210}
            videoConstraints={cam}
          />
        ) : (
          <img src={image} alt="image" />
        )}
      </div>
      <div className="ImageCam">
        {image !== '' ? (
          <MDBBtn
            variant="contained"
            //color="secondary"
            size="small"
            onClick={(e) => {
              e.preventDefault()
              setImage('')
              setcam(false)
            }}
           style = {{ backgroundColor: '#FE3D0B',
           letterSpacing: '1.5px',
           fontSize:'14px',
          }}
          >
            Retake Image
          </MDBBtn>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ paddingRight: '50px' }}>
              <MDBBtn
                variant="contained"
                //color="secondary"
                size="sm "
                onClick={(e) => {
                  e.preventDefault()
                  Camera(e)
                }}
                style = {{ backgroundColor: '#FE3D0B',
           letterSpacing: '1.5px',
           fontSize:'14px',
          }}
              >
                Enable Camera
              </MDBBtn>
            </div>
            <MDBBtn
              variant="contained"
              //color="secondary"
              size="sm "
              onClick={(e) => {
                e.preventDefault()
                capture(e)
              }}
              style = {{ backgroundColor: '#FE3D0B',
           letterSpacing: '1.5px',
           fontSize:'14px',
          }}
            >
              Capture
            </MDBBtn>
          </div>
        )}
      </div>
      <br />
      <MDBBtn onClick={handleSubmit} style = {{ backgroundColor: '#FE3D0B',
           letterSpacing: '1.5px',
           fontSize:'14px',
          }}>Submit</MDBBtn>
    </div>
  )
}
export default WebcamCapture

import React from 'react'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import Axios from 'axios'
import validator from 'validator'
import { useState } from 'react'
import drop from '../../../src/assets/Sign.png'
import signinlogo from '../../../src/assets/signinlogo.png'
import Image from '../../../src/assets/hunar-ki-gali-banner.png'
import img from '../../../src/assets/Group 1.png'
import logo from '../../../src/assets/lst-logo.jpeg'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBNavbarBrand,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog
} from 'mdb-react-ui-kit'
const Reset = (props) => {
  const formval = { fontWeight: 'bold', color: 'red' }
  const [mobile, setMobile] = useState('')
  const [Password, setPassword] = useState('')
  const [name, setname] = useState()
  // const [User, setUser] = useState('Admin');
  const [ErrConPassword, setErrConPassword] = useState('')
  const [ConPassword, setConPassword] = useState('')
  const [score, setscore] = useState()
  const [grade, setgrade] = useState()
  const [userid, setuserid] = useState()
  const [ErrMobile, setErrMobile] = useState('')
  const [ErrPassword, setErrPassword] = useState('')
  //console.log(userid);
  //console.log(grade);
  // sessionStorage.setItem("userid", userid);
  // sessionStorage.setItem("score", score);
  const Mobile = sessionStorage.getItem('Mobile') // sessionStorage.setItem("grade", grade);

  const bgimage = {
    backgroundImage: `url(${Image})`,
    height: '100vh',
    backgroundsize: 'cover',
    opacity:'0.5'
  }
  // const columns = [
  //   {
  //     value:0 ,
  //     label: 'Admin',
  //   },
  //   {
  //     value:1,
  //     label: 'Teacher',
  //   },
  //   {
  //     value:2,
  //     label: 'Student',
  //   },
  // ]
  const sendDetailsToServer = (e) => {
    e.preventDefault()

    if (Mobile !== null && Password !== null && ConPassword !==null && ErrMobile === '' && ErrPassword === '' && ErrConPassword === '') {
      const payload = {
        PASSWORD: Password,
        MOBILE_NUMBER: Mobile,
      }
      // Axios.post("http://localhost:9000/signup", payload, {
      Axios.post('/user/ResetPassword', payload, {
        'content-type': 'application/json',
        Accept: 'application/json',
      })
        .then((response) => {
         // console.log(response.data)
          if (response.status === 201) {
            //console.log(response.data)
            // props.history.replace("/Signin");
            props.history.replace('/Signin')
            // {
            //   checkmessage();
            // }
            // navigate('/Signin', { replace: true });
          } else {
           // console.log('technical error')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      toast.error(<div>{'Please Enter Vaild Details '}</div>, {
        toastId: 'success1',
        // autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
  return (
    <div>
      <div >
          <MDBRow size="12">
            <MDBCol className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor:'white'
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', height:'90px', marginLeft: '1rem' }}
                    alt="padhobadho-logo"
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="2" >

            </MDBCol>
          </MDBRow>
      </div>
      <div style={bgimage} alt="backgroundimage">
        <div>
          {/* <MDBRow>
            
            <MDBCol size="6" sm="4" style={{ paddingLeft: '150px' }}>
              {/* <MDBRow className="w-50 p-2">
                
              </MDBRow>               <div
                style={{ position: 'absolute', bottom: '10%', width: '30%' }}
              >
                <div className="centerFlex">
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '10px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <MDBTypography className="p-2">
                  <strong>
                    {' '}
                    Our Mission is to enrich people’s lives by creating the
                    largest EdTech company that allows individuals to succeed in
                    the real world.
                  </strong>
                </MDBTypography>
                
              </div>
            </MDBCol>
            <MDBCol sm="7">
              <img
                src={drop}
                width="580px"
                height="580px"
                position="absolute"
                top="0"
                right="0"
                // className="pt-5 pb-3"
                alt="studentimage"
                zIndex="1"
                className="float-end"
              ></img>
            </MDBCol>
          </MDBRow> */}
        </div>
      </div>
      <MDBModal staticBackdrop show={true} setShow={true} tabIndex='-1' >
      <MDBModalDialog size='md-down' centered>
        <MDBModalContent>          
          <MDBModalBody>
          <div style={{paddingBottom:'15px', paddingLeft:'25%'}}>
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '15px',
                    }}
                    src={signinlogo}
                    alt="padhobadho-logo"
                  ></img>
                </div>
          <MDBRow>
                  <form className="pb-5">
                    <MDBInput
                      focus
                      //label="Mobile Number"
                      placeholder="Mobile Number"
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      // size="sm"
                      required
                      variant="outlined"
                      value={Mobile}
                      // onChange={(e) => {
                      //   setEmail(e.target.value);
                      //   if (
                      //     e.target.value.length < 10 ||
                      //     e.target.value.length > 10
                      //   ) {
                      //     setErrMobile("Please enter a valid number");
                      //   } else {
                      //     setErrMobile(false, "valid number");
                      //   }
                      // }}
                    />
                    <MDBTypography style={formval}>{ErrMobile}</MDBTypography>

                    <MDBInput
                      // label="New Password"
                      type="password"
                      // variant="outlined"
                      placeholder="New Pin"
                      size="sm"
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      onChange={(e) => {
                        if(e.target.value.match("^\\d{4}$")!=null)  {
                          setPassword(e.target.value)
                          setErrPassword('')
                        } else {
                          setErrPassword('Please enter 4 digit Pin')
                        }
                      }}
                    />
                    <MDBTypography style={formval}>{ErrPassword}</MDBTypography>
                    <MDBInput
                      // label=" Confirm New Password"
                      placeholder="Confirm New Pin"
                      size="sm"
                      name=" Password"
                      type="password"
                      // variant="outlined"
                      class="borderr"
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      onChange={(e) => {
                          setConPassword(e.target.value)
                          if (Password === e.target.value) {
                            setErrConPassword('')
                          } else {
                            setErrConPassword("Pins do not match")
                          }
                      }}
                    />
                    <MDBTypography style={formval}>
                      {ErrConPassword}
                    </MDBTypography>
                    <MDBBtn
                      type="submit"
                      size="lg"
                      block
                      style={{ backgroundColor: '#FE3D0B' }}
                      onClick={(e) => sendDetailsToServer(e)}
                    >
                      submit
                    </MDBBtn>
                  </form>
                </MDBRow>
          </MDBModalBody>
          </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
    
    </div>
  )
}

export default Reset

import React, {useState} from "react";
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
import {
    MDBBtn,
    MDBIcon   
  } from "mdb-react-ui-kit";
// import fontkit from 'fontkit'; 
import Button from '@material-ui/core/Button'; 
import moment from 'moment'; 

const Certificate = ({CandidateName, CourseTitle}) => {
    //console.log(`CandidateName-  ${CandidateName}  CourseTitle- ${CourseTitle}`)
    const [link, SetLink] = useState(''); 
    //const metadata = {userName: 'Bharat Gujavarti', course:'dummy course'}

    const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
        match.toUpperCase()
    );

function generate() 
{
    const val = CandidateName;
    //check if the text is empty or not
    if (val !== "" && CandidateName !== '') {
        generatePDF(CandidateName, CourseTitle);
    };
}

const generatePDF = async (name, course) => {
 
  const existingPdfBytes = await fetch("./cert.pdf").then((res) =>
    res.arrayBuffer()
  );

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Get the first page of the document
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  // Draw a string of text diagonally across the first page
  firstPage.drawText(name, {
    x: 280 + 50 - name.length,
    y: 340,
    size: 40,
    // fon,
    color: rgb(0.1569,0.2431,0.9569),
  });


  firstPage.drawText(course, {
    x: 350,
    y: 190,
    size: 18,
    // font: SanChezFont,
    color: rgb(0.1569,0.2431,0.9569),
  });

  //current timestamp
  firstPage.drawText(moment().format("dddd, MMMM Do YYYY, h:mm:ss a"), {
    x: 350,
    y: 170,
    size: 10,
    // font: SanChezFont,
    color: rgb(211/255,211/255,211/255),
  });  

  pdfDoc.setTitle('Learning Certificate');

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

   var blob = new Blob([pdfBytes], {type: "application/pdf"});
   var link = window.URL.createObjectURL(blob);
   SetLink(link); 
   window.open(link,'', 'height=650,width=840,left=10,top=10');
}
  return( 
        <div>
        <MDBBtn id="submitBtn" onClick={generate}  tag='Certificate' color='primary'  outline floating>
        <MDBIcon fas icon='user-graduate' />
      </MDBBtn>         
        {/* {link && <a href={link} target="_blank" rel="noreferrer">View Certificate</a>} */}
        </div>
    )
};

export default Certificate;
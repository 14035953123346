import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLottie } from 'lottie-react'
import paymentsuccess from '../../src/Student/Lotties/payment-success.json';
import paymentfailure from '../../src/Student/Lotties/oppstryagain.json';
import shortid from 'shortid';
toast.configure();
const FailureLottie = () => {
     const options = {
       loop: true,
       autoplay: true,
       animationData: paymentfailure,
 
       rendererSettings: {
         preserveAspectRatio: 'none',
       },
     }
     const { View } = useLottie(options)
     return View
   }
export default async function CashFreePay(userId, amount, username, email, mobile,courseId, courseTitle) {
  
  let orderId = shortid.generate();
  let ordExpiry = new Date();
  ordExpiry.setDate(ordExpiry.getDate() + 15); //number  of days to add, e.x. 15 days
  let dateFormated = ordExpiry.toISOString().split('.')[0] + 'Z'; //2022-09-30T00:00:00Z
//     var postData = {
//         "order_id": orderId, 
//         "order_amount": amount,
//         "order_currency": "INR",
//         "customer_details": {
//              "customer_id": userId,
//              "customer_name" : username,
//              "customer_email": email,
//              "customer_phone": mobile,
//         },
//         "order_meta": {
//              "return_url": "http://localhost:3000/Student?order_id={order_id}&order_token={order_token}",
//              "notify_url": ""
//         },
//         "order_expiry_time": dateFormated,
//         "order_note": "Purchase of -" + courseTitle,
//         "order_tags": {
//              "additionalProp": courseId
//         }
//    };

const custDetails = {
     orderId:orderId,
     userId: userId.toString(),
     courseId: courseId.toString(),
     courseTitle: courseTitle,
     amount: amount.toString(),
     username: username,
     email: email,
     mobile: mobile, 
     return_url: "https://lmsdev.padhobadho.com/Student?order_id={order_id}&order_token={order_token}",
     order_expiry_time: dateFormated
     }
   //console.log('custDetails- ' + JSON.stringify(custDetails))
     var tokRes = await Axios.post('/user/request', custDetails, {
        'content-type': 'application/json',
        Accept: 'application/json',
      });
      //console.log(tokRes);
if(tokRes.data!= null)
{     
     const orderDetails = {
          ORDER_ID:tokRes.data.result.orderId,
          USER_ID: userId,
          COURSE_ID: courseId,
          ORDER_AMOUNT: amount,
          ORDER_REQUEST : 'Placing Order',
          ORDER_RESPONSE: JSON.stringify(tokRes.data.result.orderToken),
          PAYMENT_REQUEST : '',
          PAYMENT_RESPONSE: ''
          }
     //console.log(tokRes.data.result.orderToken);
     await Axios.post('/user/OrderDetails', orderDetails)
     .then((response) => {
      // console.log(response.data);
          if (response.status === 200) {
              //console.log('Successful',response.data)
          } else {
          //console.log('Technical Error')
          }
     })
     .catch((error) => {
          console.log(error)
     })

      //console.log('token.. ');
      //console.log(JSON.stringify(tokRes.data.result));
      window.location.replace(tokRes.data.result.orderToken.payment_link);
}
else{
    //  toast(
    //       <div
    //         className="px-2"
    //         style={{
    //           height: '150px',
    //           display: 'flex',
    //           justifyContent: 'center',
    //           backgroundColor: 'white',
    //           backgroundSize: 'cover',
    //           borderRadius: '14px',
    //           alignItems: 'center',
    //           lineHeight: '20px',
    //         }}
    //         // className="rcorners"
    //       >
    //         <FailureLottie />
    //         {'Payment Failed!! Something Went Wrong!'}
    //       </div>,
    //       {
    //         toastId: 'failure',
    //         autoClose: 3000,
    //         position: toast.POSITION.TOP_CENTER,
    //       },
    //     )
}
}


import React from "react";
import Navbar from "./navbar.js";
import "./Course.css";
import Image from "../../assets/Background.png";
import Accordion from "react-bootstrap/Accordion";
import Axios from "axios";
import { useState, useEffect } from "react";
import queryString from "query-string";
import PDFViewer from "pdf-viewer-reactjs";
import { useLottie } from "lottie-react";
import { useLocation } from "react-router-dom";
import animationData from "../Lotties/SuccessLottie.json";
//import SuccessLottie from "../Lotties/SuccessLottie";
import { withRouter } from "react-router-dom";
import Quiz from "./Quiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBAccordion,
  MDBAccordionItem,
  MDBDropdownDivider,
  MDBListGroup,
  MDBListGroupItem,
  MDBTypography,
  MDBBadge,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
toast.configure();
function Lessons(props) {
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundsize: "cover",
    height: "100%",
  };
  const location = useLocation();
  const [CourseContents, setCourseContents] = useState([]);
  const [courseId, setCourseId] = useState();
  const [courseTitle, setCourseTitle] = useState("");
  const [percentage, setPercentage] = useState();
  const [Coins, setCoins] = useState(false);
  // const [reloadQuiz, setReloadQuiz] = useState(false);
  const [quizQuest, _setQuizQuestions] = useState();
  const [showSuccessLottie, _setShowSuccessLottie] = useState(false);
  const [currentContentObj, _setCurrentContentObj] = useState(null);
  const [activeContentId, _setActiveContentId] = useState();
  const [quizCompleted, _setQuizCompleted] = useState();
  const stateRef = React.useRef(currentContentObj);
  const lottieRef = React.useRef(showSuccessLottie);
  const activeIdRef = React.useRef(activeContentId);
  const quizComplRef = React.useRef(quizCompleted);
  const quizQuestRef = React.useRef(quizQuest);
  const UserId = sessionStorage.getItem("userid");
  var divVideoPlayer = document.getElementById("videoPlayer");
  var divDocViewer = document.getElementById("docViewer");
  var divQuizViewer = document.getElementById("quizViewer");
  let course_ID = sessionStorage.getItem("courseId");
  let course_Title = sessionStorage.getItem("courseTitle");
  const timer = null;
  const placementExample = React.useRef(null);
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const [alertMessage, setAlertMessage] = useState("");
  const notify = () =>
    toast.success("You have successfully completed the topic", {
      toastId: "success1",
      autoClose: 4000,
    });
  const questions = [
    {
      question: "Arrange the number -3/5, -7/10and -5/8 in ascending order.",
      answers: [
        "-5/8<-3/5<-7/10",
        "3/5<-7/10<-5/8",
        "7/10<-5/8<-3/5",
        "None of these",
      ],
      correctAnswerIndex: 2,
    },
  ];
  const setQuizQuestions = (data) => {
    quizQuestRef.current = data;
    _setQuizQuestions(data);
  };
  const setQuizCompleted = (data) => {
    quizComplRef.current = data;
    _setQuizCompleted(data);
  };
  const setCurrentContentObj = (data) => {
    stateRef.current = data;
    _setCurrentContentObj(data);
  };
  const setShowSuccessLottie = (isVisible) => {
    let lDiv = document.getElementById("succ-lot");

    if (isVisible) {
      lDiv.style.zIndex = "10";
    } else {
      lDiv.style.zIndex = "-1";
    }
    lottieRef.current = isVisible;
    _setShowSuccessLottie(isVisible);
  };
  const setActiveContentId = (data) => {
    activeIdRef.current = data;
    _setActiveContentId(data);
  };
  useEffect(() => {
    // setReloadQuiz(false);
    let cObj = sessionStorage.getItem("courseObj");
    let obj = JSON.parse(cObj);
    setCourseId(obj.COURSE_ID);
    setCourseTitle(obj.COURSE_TITLE);
    setPercentage(0);
    setShowSuccessLottie(false);
    document.getElementById("videoPlayer").style.display = "none";
    Axios.get(`/user/Contents/${obj.COURSE_ID}/${UserId}`).then((response) => {
      response.data.recordset.map((content) => {
        //console.log(content);
        content.PERCENTAGE_VIEWED =
          (content.CURRENT_PROGRESS / content.CONTENT_VIDEO_DURATION) * 100;
        if (content.CONTENT_PROGRESS_STATUS === 3) {
          content.CURRENT_PROGRESS = 0;
          content.PERCENTAGE_VIEWED = 100;
        }
      });
      setCourseContents(response.data.recordset);
      //if(response.data.recordset)
      let activeContentObj = response.data.recordset.filter(
        (content) => content.CONTENT_PROGRESS_STATUS === 2
      )[0];
      let lessonView = document.getElementById("lessonView");
      if (activeContentObj !== null && activeContentObj !== undefined) {
        setActiveContentId(activeContentObj.PARENT_ID);
        //console.log(`pending-- ${JSON.stringify(activeContentObj.CONTENT_ID)}`);
        lessonView.activeKey = activeContentObj.PARENT_ID;
      } else {
        activeContentObj = response.data.recordset.filter(
          (content) => content.PARENT_ID === 0
        )[0];
        //console.log(`parent-- ${activeContentObj.CONTENT_ID}`);
        setActiveContentId(activeContentObj.CONTENT_ID);
        lessonView.activeKey = activeContentObj.CONTENT_ID;
        //  activeContentObj = response.data.recordset.filter((content) => (content.PARENT_ID === activeContentObj.CONTENT_ID))[0];
        //  console.log(`parent2-- ${activeContentObj}`);
      }
      setCurrentContentObj(activeContentObj);
      loadLesson(activeContentObj);
    });
  }, [activeContentId]);

  const paused = (event) => {
    if (event.target.contentId) {
      //console.log(`paused--contentId- ${event.target.contentId}`);
      if (event.target.currentTime > 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          2,
          event.target.currentTime,
          event.target.duration
        );
      } else if (event.target.currentTime < 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          1,
          event.target.currentTime,
          event.target.duration
        );
      }
    }
  };
  const started = (event) => {
    if (event.target.contentId) {
      //console.log(`started--contentId- ${event.target.contentId}`);
    }
  };
  const finished = (event) => {
    //console.log(currentContentObj.WATCH_COUNT);
    //if(currentContentObj && currentContentObj.WATCH_COUNT === 0){
    setShowSuccessLottie(true);
    notify();
    //}
    //toast.success("You have successfully completed the topic", { autoClose: 4000 });

    if (event.target.contentId) {
      //console.log(`finished--contentId- ${event.target.contentId}`);
      if (event.target.currentTime === event.target.duration)
        UpdateProgress(
          UserId,
          event.target.contentId,
          3,
          event.target.currentTime,
          event.target.duration
        );
      currentContentObj.WATCH_COUNT = currentContentObj.WATCH_COUNT + 1;
    }
    setTimeout(() => {
      setShowSuccessLottie(false);
    }, 4000);
    //const instance = document.getElementById('liveToast');
    // //instance.update({ position: 'top-right' });
    //instance.toast('show');
    // instance.style.zIndex = "10";
  };
  const UpdateProgress = (
    userId,
    contentId,
    videoStatus,
    currentProgress,
    duration
  ) => {
  //  // console.log(
  //     `userId- ${userId}  contentId- ${contentId}  videoStatus-  ${videoStatus}  currentProgress${currentProgress}`
  //   );
    Axios.post("/user/UpdateUserProgress", {
      USER_ID: userId,
      CONTENT_ID: contentId,
      VIDEO_STATUS: videoStatus,
      CURRENT_PROGRESS: currentProgress,
    }).then((response) => {
      if (response.status === 200) {
      } else {
       // console.log("Update progress error");
      }
    });
    let objCC = CourseContents.find(
      (record) => record.CONTENT_ID === contentId
    );
    let coins = 0;
    if (videoStatus === 3) {
      coins = CourseContents.find(
        (record) => record.CONTENT_ID === contentId
      ).POINTS_TO_BE_AWARDED;
      setCoins(true);
    }
    if (objCC !== null && objCC !== undefined) {
      if (videoStatus === 3) {
        objCC.CURRENT_PROGRESS = 0;
        CourseContents.find(
          (record) => record.CONTENT_ID === contentId
        ).CURRENT_PROGRESS = 0;
      } else {
        objCC.CURRENT_PROGRESS = currentProgress;
        CourseContents.find(
          (record) => record.CONTENT_ID === contentId
        ).CURRENT_PROGRESS = currentProgress;
      }
      CourseContents.find(
        (record) => record.CONTENT_ID === contentId
      ).CONTENT_PROGRESS_STATUS = videoStatus;

      CourseContents.find((record) => record.CONTENT_ID === contentId).SCORE =
        coins;
      CourseContents.find(
        (record) => record.CONTENT_ID === contentId
      ).PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      //console.log(JSON.stringify(CourseContents,null,4))
      objCC.CONTENT_PROGRESS_STATUS = videoStatus;

      objCC.SCORE = coins;
      objCC.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      setCurrentContentObj(objCC);
    }
    // if(currentContentObj !== null && currentContentObj!== undefined)
    // {
    //   //console.log(JSON.stringify(currentContentObj,null,4))
    //   currentContentObj.CONTENT_PROGRESS_STATUS = videoStatus;
    //   currentContentObj.CURRENT_PROGRESS = currentProgress;
    //   currentContentObj.SCORE = coins;
    //   currentContentObj.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
    // }
  };
  const LessonCompletedLottie = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidyMid meet",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function loadLesson(contentObj) {
    const vidEle = document.getElementById("videoCart");
    if (contentObj.IS_FREE_PREVIEW === 1) {
      setShowSuccessLottie(false);
      /*setCurrentContentObj(contentObj);*/
      setCurrentContentObj(contentObj);
      //console.log(contentObj.CONTENT_VIDEO);
      if (contentObj.CONTENT_TYPE === 1) {
        if (divDocViewer) {
          divDocViewer.style.display = "none";
        }
        if (divQuizViewer) divQuizViewer.style.display = "none";
        if (divVideoPlayer) divVideoPlayer.style.display = "block";
        // if(vidEle)
        // {
        let filepath = `/static/videos/${contentObj.CONTENT_VIDEO}#t=${contentObj.CURRENT_PROGRESS}`;
        //filepath = encodeURI(filepath);
        //console.log(filepath);
        vidEle.setAttribute("src", filepath);
        vidEle.setAttribute("preload", "metadata");

        if (vidEle.onplay) vidEle.removeEventListener("play", started);
        if (vidEle.onpause) vidEle.removeEventListener("pause", paused);
        if (vidEle.onended) vidEle.removeEventListener("ended", finished);
        vidEle.addEventListener("play", started, { passive: true });
        vidEle.addEventListener("pause", paused, { passive: true });
        vidEle.addEventListener("ended", finished, { passive: true });
        vidEle.addEventListener("contextmenu", (event) => {
          event.preventDefault();
        });
        vidEle.contentId = contentObj.CONTENT_ID;
        //vidEle.setAttribute('src',`http://localhost:3860/static/videos/introduction%20and%20properties.mp4`);
        vidEle.load();
        vidEle.style.display = "block";
        //vidEle.play()
        //}
      } else if (contentObj.CONTENT_TYPE === 2) {
        vidEle.setAttribute("src", "");
        divVideoPlayer.style.display = "none";
        divQuizViewer.style.display = "none";
        divDocViewer.style.display = "none";
      } else if (contentObj.CONTENT_TYPE === 3) {
        // <Quiz questions={quizQuest} parentCallback = {handleCallback}  />
        // setReloadQuiz(true);
        divVideoPlayer.style.display = "none";
        divDocViewer.style.display = "none";
        divQuizViewer.style.display = "block";
        setQuizQuestions(questions);
        vidEle.setAttribute("src", "");
      }
      //v.load();
    } else {
      vidEle.setAttribute("src", "");
      divVideoPlayer.style.display = "none";
      divQuizViewer.style.display = "none";
      divDocViewer.style.display = "none";
      setAlertMessage("");
      setBasicModal(true);
    }
  }
  const handleCallback = (quizComplete) => {
    setQuizCompleted(true);
    //divQuizViewer.style.display="none";
    //console.log(`quizCompleted- ${quizCompleted} currentContentObj.CONTENT_ID- ${currentContentObj.CONTENT_ID}`);
    UpdateProgress(UserId, currentContentObj.CONTENT_ID, 3, 0);
  };

  const goToNextLesson = (e) => {
    setShowSuccessLottie(false);
    let contentIndex = CourseContents.findIndex(
      (record) => record.CONTENT_ID === currentContentObj.CONTENT_ID
    );
    if (currentContentObj) {
      UpdateProgress(
        UserId,
        currentContentObj.CONTENT_ID,
        3,
        currentContentObj.CONTENT_VIDEO_DURATION
      );
    }
    let nextIndex = contentIndex + 1;
    let nextEle = null;
    if (currentContentObj) {
      if (nextIndex < CourseContents.length) {
        if (CourseContents[nextIndex].PARENT_ID === 0) {
          nextIndex = nextIndex + 1;
          if (CourseContents[nextIndex]) {
            nextEle = CourseContents[nextIndex];
          }
        } else {
          nextEle = CourseContents[nextIndex];
        }
        if (nextEle) {
          setCurrentContentObj(nextEle);
          loadLesson(nextEle);
        }
      } else {
      }
    }
  };
  const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) {
        return 1;
      }
      if (b[field] > a[field]) {
        return -1;
      }
      return 0;
    });
  };

  const sortDesc = (arr, field) => {
    return arr.sort((a, b) => {
      if (a[field] > b[field]) {
        return -1;
      }
      if (b[field] > a[field]) {
        return 1;
      }
      return 0;
    });
  };

  function toggleActive(id) {
    if (activeContentId === id) {
      setActiveContentId(null);
    } else {
      setActiveContentId(id);
    }
  }

  const customSort = (a, b) => {
    return (
      new Date(a.STATUS_DATE).getTime() > new Date(b.STATUS_DATE).getTime()
    );
  };
  return (
    <>
      <div id="succ-lot" className="lottie-wrapper">
        {/* <div className="rcorners" style={{ fontFamily: "Maiandra" }}>
          <span>You have successfully completed the topic</span>
        </div> */}
        {/* {lottieRef.current &&  <SuccessLottie/>} */}
        {/* <h3> You have successfully completed the topic</h3> */}
        {showSuccessLottie && <LessonCompletedLottie />}
      </div>
      {/* <div id="liveToast" class="toast" style={{width: '350px', position: 'fixed', top: '0', left: '50%', transform: 'translate(-50%, 0px)'}} data-delay="2000" data-animation="true">  
    <div id="LiveToastBG" class="toast-header alert-info">
      <span id="ToastIcon"></span>&nbsp;&nbsp;
      <strong class="mr-auto"><span id="ToastHeadline"></span></strong>
      <small><span id="ToastMessageTimeSinceSent"></span></small>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="toast-body" style={{backgroundColor: '#FFFFFF'}}>
        <span id="ToastMessage">here...</span>
      </div>

  </div> */}
      <div style={bgimage} alt="backgroundimage">
        <div>
          <Navbar props={Coins} />
        </div>
        <div>
          <div style={{ margin: "15px" }}>
            <a href={`/Student`}>
              <MDBIcon
                size="lg"
                style={{ color: "#05818D", paddingLeft: "10px" }}
                icon="arrow-circle-left"
              />{" "}
            </a>
            <span
              width="30%"
              style={{
                fontSize: "28px",
                paddingLeft: "2rem",
                fontFamily: "Maiandra",
                fontWeight: "bold",
              }}
            >
              {courseTitle}
            </span>
            <span style={{ paddingLeft: "10px", paddingBottom: "0px" }}>
              <MDBBtn
                className="btn btn-default btn-sm float-end"
                style={{ backgroundColor: "#05818D", width: "240px" }}
                onClick={(e) => goToNextLesson(e)}
              >
                Complete and Continue
              </MDBBtn>
            </span>
          </div>
          <MDBRow className="px-2">
            <MDBCol sm="4">
              <Accordion
                id="lessonView"
                key="lessonView"
                defaultActiveKey={4}
                eventkey={activeContentId}
                style={{ fontFamily: "Maiandra" }}
              >
                {CourseContents &&
                  CourseContents.filter(
                    (content) => content.PARENT_ID === 0
                  ).map((content_det, mainIndex) => (
                    <Accordion.Item
                      style={{ padding: "0rem" }}
                      id={mainIndex + content_det.CONTENT_ID}
                      key={mainIndex + content_det.CONTENT_ID}
                      eventKey={content_det.CONTENT_ID}
                    >
                      <Accordion.Header>
                        {content_det.CONTENT_TITLE}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul
                          className="list-group list-group-flush"
                          style={{ padding: "0px", margin: "0px" }}
                        >
                          {CourseContents.filter(
                            (cContent) =>
                              cContent.PARENT_ID === content_det.CONTENT_ID
                          ).map((obj, index) => (
                            <li
                              key={obj.CONTENT_ID}
                              className="list-group-item"
                              style={{
                                cursor: "pointer",
                                paddingLeft: "0px",
                              }}
                              onClick={(e) => loadLesson(obj)}
                            >
                              {obj.CONTENT_TYPE === 1 ? (
                                <MDBIcon
                                  icon="video"
                                  size="md"
                                  style={{
                                    paddingRight: "1rem",
                                    color: "#05818D",
                                  }}
                                />
                              ) : (
                                <MDBIcon
                                  icon="file-alt"
                                  size="md"
                                  style={{
                                    paddingRight: "1rem",
                                    color: "#05818D",
                                  }}
                                />
                              )}

                              {obj.CONTENT_TITLE}

                              {obj.CONTENT_PROGRESS_STATUS === 3 ? (
                                // <MDBIcon
                                //   fas
                                //   icon="check"
                                //   size="md"
                                //   className="float-end"
                                //   style={{
                                //     color: "#008000",
                                //   }}
                                // />
                                <></>
                              ) : (
                                <div>
                                  <span className="badge rounded-pill badge-warning float-end">
                                    {Math.round(obj.PERCENTAGE_VIEWED) > 0 &&
                                    obj.PERCENTAGE_VIEWED !== undefined &&
                                    Math.round(obj.PERCENTAGE_VIEWED) < 100
                                      ? Math.round(
                                          obj.PERCENTAGE_VIEWED
                                        ).toString() + "%"
                                      : ""}
                                  </span>
                                  {/* <MDBBadge
                                    className="text-dark me-2"
                                    color="light"
                                  >
                                    {(obj.WATCH_COUNT > 0 &&
                                    obj.WATCH_COUNT !== undefined
                                      ? obj.WATCH_COUNT
                                      : "0 ") + "views"}
                                  </MDBBadge> */}
                                  {/* <span className="badge rounded-pill text-dark ms-2">
                                    {(obj.WATCH_COUNT > 0 &&
                                    obj.WATCH_COUNT !== undefined
                                      ? obj.WATCH_COUNT
                                      : "0 ") + "views"}
                                  </span> */}
                                </div>
                              )}
                              {obj.WATCH_COUNT > 0 &&
                              obj.WATCH_COUNT !== undefined ? (
                                <MDBBadge
                                  className="text-dark float-end"
                                  color="light"
                                >
                                  {obj.WATCH_COUNT}
                                  <MDBIcon
                                    fas
                                    icon="eye"
                                    size="md"
                                    className="float-end"
                                    style={{
                                   
                                      paddingLeft: "2px",
                                    }}
                                  />
                                </MDBBadge>
                              ) : (
                                <span></span>
                              )}
                              {obj.SCORE > 0 && obj.SCORE !== undefined ? (
                                <MDBBadge
                                  className="text-dark float-end"
                                  color="light"
                                >
                                  {obj.SCORE}
                                  <MDBIcon
                                    fab
                                    icon="coin"
                                    // icon="coins"
                                    size="md"
                                    className="float-end"
                                    style={{
                                      color: "orange",
                                      paddingLeft: "2px",
                                    }}
                                  />
                                </MDBBadge>
                              ) : (
                                <span></span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
              {/* <MDBAccordion initialActive={4}>
            {CourseContents &&
              CourseContents.filter((content) => content.PARENT_ID === 0).map((content_det,mainIndex) => (
                <MDBAccordionItem collapseId={content_det.CONTENT_ID} id={content_det.CONTENT_ID} key = {content_det.CONTENT_ID} headerTitle={content_det.CONTENT_TITLE} >
                    <MDBListGroup flush id={'lst'+content_det.CONTENT_ID} key={'lst'+content_det.CONTENT_ID} style={{Height: "100%"}}>
                    {CourseContents.filter((cContent) => cContent.PARENT_ID === content_det.CONTENT_ID).map((obj, index) => (
                      <MDBListGroupItem id={obj.CONTENT_ID} key={obj.CONTENT_ID} style={{cursor:'pointer'}} onClick={(e) => loadLesson(obj)}>{obj.CONTENT_TITLE}</MDBListGroupItem>
                    ))}
                    </MDBListGroup>

                </MDBAccordionItem>
              ))}
              backgroundColor: "#ffffff",
                      variant: "outlined",
                      width: "380px",
                      height: "26px",
                      borderColor:"light gray",
                      borderRadius:"4px",
                      paddingLeft: "0.99em",
                      paddingRight: "0.99em",
                      paddingTop: "0.43em",
                      paddingBottom: "0.35em",
                      fontSize: ".775rem",
                      lineHeight: "1.6",
          </MDBAccordion> */}
            </MDBCol>
            <MDBCol size="12" sm="8">
              <span
                width="30%"
                style={{
                  fontSize: "20px",
                  fontFamily: "Maiandra",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                {currentContentObj
                  ? currentContentObj.CONTENT_TITLE.length > 65
                    ? currentContentObj.CONTENT_TITLE.substring(0, 65) + "..."
                    : currentContentObj.CONTENT_TITLE
                  : ""}
              </span>
              <MDBRow id="videoPlayer" className="pb-20">
                {/* <div >className="video-cart-wrapper" */}
                <video
                  id="videoCart"
                  controls
                  controlsList="nodownload"
                ></video>
              </MDBRow>
              <MDBRow id="docViewer" style={{ display: "none" }}>
                {/* {<PDFViewer id= "docV" document={{
                            url: 'http://lmsdev.padhobadho.com/static/docs/sample.pdf', }}                
                    />
          } */}
              </MDBRow>
              <MDBRow id="quizViewer" style={{ display: "none" }}>
                <div id="startQuiz" className="centerFlex">
                  <Quiz
                    id="quizComp"
                    key={quizQuest}
                    questions={quizQuest}
                    parentCallback={handleCallback}
                  />
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader style={{ backgroundColor: "orange" }}>
              <MDBModalTitle>Padho Bhado!!</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="warning"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Please Enroll to View this topic!!</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{ backgroundColor: "#05818D" }}
                onClick={toggleShow}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default Lessons;

import React from 'react';

function TimeTable() {
  return (
    <div className='reports'>
      <h1>Time Schedule</h1>
    </div>
  );
}

export default TimeTable;

import { MDBFooter, MDBDropdownDivider, MDBIcon } from "mdb-react-ui-kit";
import Footer from './footer'
import './Course.css'
import Navbar from './navbar.js'
export default function CoinsLevelsInfo(){
    return(
        <div>

    
<Navbar></Navbar>
<br/>
<div>
<center>
<table className="coinstable">
    
  <tr>
    <th bgcolor="#FE3D0B">Event</th>
    <th bgcolor="#FE3D0B"></th>
    <th bgcolor="#FE3D0B"></th>
  </tr>
  <tr>
    <td>Signup PadhoBadho</td>
    <td>250</td>
    <td>1000</td>
  </tr>
  <tr>
    <td>Signup for a Course(free)</td>
    <td>50</td>
    <td>250</td>
  </tr>
  <tr>
    <td>Signup for a Course (paid)</td>
    <td>250</td>
    <td>1000</td>
  </tr>
  <tr>
    <td>Complete a Lesson</td>
    <td>25</td>
    <td>50</td>
  </tr>
  <tr>
    <td>Complete a Chapter(bonus)</td>
    <td>100</td>
    <td>250</td>
  </tr>
  <tr>
    <td>Complete a Course</td>
    <td>250</td>
    <td>1000</td>
  </tr>
  <tr>
  <td>Complete Quiz</td>
    <td>25</td>
    <td>50</td>
 </tr>
 <tr>
    <td> Complete</td>
    <td></td>
    <td></td> 
 </tr>
 <tr>
    <td>Refer a friend(15 days)</td>
    <td>1000</td>
    <td>1000</td>
 </tr>
</table>
</center>

<br/>

<center>
<table>
<tr>
    <th bgcolor="#1F594A">From</th>
    <th bgcolor="#1F594A">To</th>
    <th bgcolor="#1F594A">Level Title</th>
</tr>
<tr>
    <td>0</td>
    <td>5000</td>
    <td>Beginner</td>
</tr>
<tr>
    <td>5001</td>
    <td>10000</td>
    <td>Trainee</td>
</tr>
<tr>
    <td>10001</td>
    <td>15000</td>
    <td>Amateur</td>
</tr>
<tr>
    <td>15001</td>
    <td>20000</td>
    <td>Hustler</td>
</tr>
<tr>
    <td>20001</td>
    <td>25000</td>
    <td>Hotshot</td>
</tr>
<tr>
    <td>25001</td>
    <td>30000</td>
    <td>Virtuoso</td>
</tr>
<tr>
    <td>3001</td>
    <td>35000</td>
    <td>Expert</td>
</tr>
<tr>
    <td>35001</td>
    <td>40000</td>
    <td>Veteran</td>
</tr>
<tr>
    <td>40001</td>
    <td>45000</td>
    <td>Semi-Pro</td>
</tr>
<tr>
    <td>45001</td>
    <td>50000</td>
    <td>Professional</td>
</tr>
<tr>
    <td>50001</td>
    <td>55000</td>
    <td>Master</td>
</tr>
<tr>
    <td>55001</td>
    <td>60000</td>
    <td>Champ</td>
</tr>
<tr>
    <td>60001</td>
    <td>65000</td>
    <td>Superstar</td>
</tr>
<tr>
    <td>65001</td>
    <td>70000</td>
    <td>Hero</td>
</tr>
<tr>
    <td>70001</td>
    <td>75000</td>
    <td>Legend</td>
</tr>
<tr>
    <td>75001</td>
    <td>80000</td>
    <td>Demi-God</td>
</tr>
<tr>
    <td>80001</td>
    <td></td>
    <td>Immortal</td>
</tr>
</table>  
</center>
</div>


        <Footer></Footer></div>
    )}
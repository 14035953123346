import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Signin from './Signin'
import Student from './Student/Student'
import SignUp from './SignUp'
import Description from './Student/Pages/CourseDescrption'
import Profile from './Student/Pages/Profile'
import Navbar from './Student/Pages/navbar'
import Lessons from './Student/Pages/lessons'
import LessonDetails from './Student/Pages/LessonDetails'
import Eprofile from './Student/Pages/Editableprofile'
import PreviewLesson from './Student/Pages/PreviewLesson'
import LessonsPreview from './Student/Pages/LessonsPreview'
import Certificate from './Student/Pages/Certificate'
import Forgotpassword from './Student/Pages/Forgotpassword'
import Reset from './Student/Pages/Reset'
import WebcamCapture from './Student/Pages/Camera'
import ViewLesson from './Student/Pages/ViewLesson'
import MobilePage from './Student/Pages/MobilePage'
import ViewBook from './Student/Pages/ViewBook'
import CoinsLevelsInfo from './Student/Pages/CoinsLevelsInfo'

class App extends Component {
  
  // componentWillMount = () => {
  //   this.loadScript('https://checkout.razorpay.com/v1/checkout.js')
  // }
  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={Signin} />
            <Route exact path="/Signin" component={Signin} />
            <Route path="/Student" component={Student} />
            <Route path="/SignUp" component={SignUp} />
            <Route path="/Profile" exact component={Profile} />
            <Route path="/Nav" exact component={Navbar} />
            <Route path="/Course" exact component={LessonDetails} />
            <Route path="/Certificate" exact component={Certificate} />
            <Route path="/CoursePreview" exact component={PreviewLesson} />
            <Route path="/Content" component={Lessons} />
            {/* <Route path="/Contents" component={LessonsPreview} /> */}
            <Route path="/Description" component={Description} />
            <Route path="/EditProfile" exact component={Eprofile} />
            <Route path="/forgotpassword" exact component={Forgotpassword} />
            <Route path="/camera" exact component={WebcamCapture} />
            <Route path="/Reset" exact component={Reset} />
            <Route path="/Contents" component={ViewLesson} />
            <Route path="/mobilepage" component={MobilePage} />
            <Route path="/ViewBook" component={ViewBook} />
            <Route path="/CoinsLevelsInfo" component ={CoinsLevelsInfo} />
            <Student />
          </Switch>
        </Router>
      </>
    )
  }
}
// //"http://localhost:3860/",
export default App

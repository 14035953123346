import React from 'react';

function Notifications() {
  return (
    <div className='products'>
      <h1>Class Notifications</h1>
    </div>
  );
}

export default Notifications;

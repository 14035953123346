import React, { useState } from 'react'
import logo from '../../../src/assets/Final-Logo.png'
import signinlogo from '../../../src/assets/signinlogo.png'

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNavbarBrand

} from 'mdb-react-ui-kit'

const MobilePage = (props) => { 
  return (    
    <div>
       <MDBRow className='pt-1' size="12">
            <MDBCol className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor:'white'
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', height:'90px', marginLeft: '1rem' }}
                    alt=""
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
           
          </MDBRow>
          <MDBRow className='pt-5 centerFlex'>
<MDBCol>
<div style={{paddingBottom:'15px', paddingLeft:'25%'}}>
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '15px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <div
                style={{  fontFamily: 'Playfair Display', fontWeight: 'bold', fontSize:'18px', padding:'15px' }}>
                  Please download Mobile application from Playstore. This website is viewable in desktop mode.
                  <br/>
                  <div>You may download the app from<a href="https://padhobadho.s3.ap-south-1.amazonaws.com/mobileapp/padhobadho01sep22.apk"> My SkillForge Mobile App</a>
                  <br/>Please give necessary access permissions to Install the App
                  </div>
                  <br/>
                  <div>Click here to go to <a href="https://www.padhobadho.com">Home Page</a></div> 
                </div>
</MDBCol>
            </MDBRow>
      {/* <div>
        <h2>Please download Mobile application from Playstore. This website is viewable in desktop mode.</h2>
      </div> */}
     
    </div>
  )
}

export default MobilePage;

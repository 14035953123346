
import { MDBFooter, MDBDropdownDivider, MDBIcon } from "mdb-react-ui-kit";
import footerLogo from '../../../src/assets/footer-logo.png'
import './Course.css'
export default function Footer(){
    return(
        <MDBFooter className="absolute">
        <div class="row footer">
        <div class="col-md-1 col-xs-12"></div>
      <div class="col-md-3 col-sm-12 col-xs-12"> <a href="http://www.padhobadho.com/index.html"><img src={footerLogo} alt="footer-logo"/></a> </div>
      <div class="col-md-5 col-xs-12"> <a href="http://www.padhobadho.com/index.html">Home</a> <a href="padho-se-judo.html">Padho</a> <a href="http://www.padhobadho.com/badho-se-judo.html">Badho</a> <a href="http://www.padhobadho.com/humari-team.html">About Us</a> <a href="http://www.padhobadho.com/contact-n.html">Contact Us</a> </div>
      <div class="col-md-2 col-sm-12 col-xs-12 p-2"> <a href="https://www.instagram.com/padho.badho/" target="_blank"><MDBIcon fab icon="instagram" /></a> <a href="https://www.facebook.com/PadhoBadho-107136478756171" target="_blank"><MDBIcon fab icon="facebook" /></a> <a href="https://www.youtube.com/channel/UCq2hK6s9WwsVlqGvHmWcYLQ" target="_blank"><MDBIcon fab icon="youtube" /></a> </div>
      <div class="col-md-1 col-xs-12"></div>
      </div>
      <MDBDropdownDivider></MDBDropdownDivider> 
    <div class="row f-1">
      <div class="col-md-12 text-center p-1" style={{fontSize: '10px'}}>
      © 2022 Copyright: 
              <a className="text-black" href="http:www.padhobadho.com/">
                PadhoBadho
              </a>
      </div>
    </div>
    </MDBFooter>
    )
}
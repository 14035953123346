import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import firebase from '../../../src/firebase'
import Image from '../../../src/assets/hunar-ki-gali-banner.png'
import drop from '../../../src/assets/Sign.png'
import signinlogo from '../../../src/assets/signinlogo.png'
import img from '../../../src/assets/Group 1.png'
import logo from '../../../src/assets/lst-logo.jpeg'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBNavbarBrand,
  MDBIcon,
  MDBFooter,
  MDBDropdownDivider,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalFooter, 
  MDBModalDialog
} from 'mdb-react-ui-kit'
import validator from 'validator'
import Axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const Forgotpassword = (props) => {
  const formval = { fontWeight: 'bold', color: 'red' }
  const [Password, setPassword] = useState('')
  const [mobile, setmobile] = useState('')
  const [ErrPassword, setErrPassword] = useState('')
  const [ErrConPassword, setErrConPassword] = useState('')
  const [ConPassword, setConPassword] = useState('')
  const [ErrMobile, setErrMobile] = useState('')
  const [flag, setFlag] = useState(false)
  const [otp, setotp] = useState('')
  const [result, setResult] = useState('')
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundsize: 'cover',
    height: '100vh',
    opacity:'0.6'
  }
  sessionStorage.setItem('Mobile', mobile)
  const configureCaptcha = () => {
    //console.log('recaptcha')
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //console.log(response)
          setResult(response)
          setFlag(true)

          onSignInSubmit()

          //console.log('Recaptcha verified')
        },
        defaultCountry: 'IN',
      },
    )
  }
  const onSignInSubmit = (e) => {
if(mobile!== '' || mobile){
    e.preventDefault()
    //{
      configureCaptcha()
    //}
    const phoneNumber = '+91' + mobile
    //console.log('phoneNumber')
    //console.log(phoneNumber)
    const appVerifier = window.recaptchaVerifier
   // console.log(appVerifier)
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
       // console.log('OTP has been sent')
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log('SMS not sent',error)
      })
    }
  else
  {
    toast.error(<div>{'Please Enter Mobile Number'}</div>, {
      toastId: 'success1',
      // autoClose: 2000,
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  }
  const onSubmitOTP = (e) => {
    e.preventDefault()
if(mobile!=='' && otp!==''){
    const code = otp
    //console.log(code)
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user
        //console.log(JSON.stringify(user))
        //console.log('User is verified')
        //{
          props.history.replace('/Reset')
        //}
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      })
    }
    else
    {
      toast.error(<div>{'Please Enter Mobile Number & OTP'}</div>, {
        toastId: 'success1',
        // autoClose: 2000,
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    }
  return (
    
    <div>
      <div >
          <MDBRow size="12">
            <MDBCol className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor:'white'
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', marginLeft: '1rem' }}
                    alt=""
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="2" >

            </MDBCol>
          </MDBRow>
      </div>
      <div style={bgimage} alt="backgroundimage">
          {/* <MDBRow>
            <MDBCol size="1" className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', marginLeft: '1rem' }}
                    alt=""
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="6" sm="4" style={{ paddingLeft: '150px' }}>
              <div
                style={{ position: 'absolute', bottom: '10%', width: '30%' }}
              >
                <div className="centerFlex">
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '10px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <MDBRow size="6" sm="4">
                  
                </MDBRow>

                {/* <MDBRow
                  style={{ display: flag ? 'block' : 'none' }}
                  className="px-2"
                >
                  <MDBInput
                    // label="OTP "
                    placeholder="OTP"
                    name="OTP"
                    type="int"
                    // size="sm"
                    // variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#ffffff',
                      width: '100%',
                      height: '50px',
                      paddingLeft: '7px',
                    }}
                    required
                    onChange={(e) => {
                      setotp(e.target.value)
                    }}
                  />
                  <br />
                  <MDBBtn
                    variant="contained"
                    block
                    size="lg"
                    style={{ backgroundColor: '#FE3D0B' }}
                    type="submit"
                    onClick={(e) => onSubmitOTP(e)}
                  >
                    Submit
                  </MDBBtn>
                </MDBRow> 
              </div>
            </MDBCol>
            <MDBCol sm="7">
              <img
                src={drop}
                width="580px"
                height="580px"
                position="absolute"
                top="0"
                right="0"
                // className="pt-5 pb-3"
                alt="studentimage"
                zIndex="1"
                className="float-end"
              ></img>
            </MDBCol>
          </MDBRow>
         */}
      </div>
      {/* <MDBFooter className="fixed">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <MDBCol
            size="10"
            className="text-center p-3"
            style={{
              fontSize: '10px',
              // backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            © 2022 Copyright:
            <a className="text-black" href="http://lmsdev.padhobadho.com/">
              PadhoBadho
            </a>
          </MDBCol>
          <MDBCol
            size="2"
            //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
          >
            <section
              className="float-end "
              style={{
                paddingTop: '5px',
                paddingRight: '10px',
                fontSize: '10px',
              }}
            >
              <a className="  btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="facebook-f" />
              </a>
              <a
                className=" btn-floating text-black "
                href="#!"
                role="button"
                size="10px"
              >
                <MDBIcon fab icon="instagram" />
              </a>

              <a className=" btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="linkedin-in" />
              </a>
            </section>
          </MDBCol>
        </div>
        <MDBDropdownDivider></MDBDropdownDivider>
      </MDBFooter> */}
      <MDBModal staticBackdrop show={true} setShow={true} tabIndex='-1' >
      <MDBModalDialog size='md-down' centered>
        <MDBModalContent>
          
          <MDBModalBody>
            <MDBCol>
            <div style={{paddingBottom:'15px', paddingLeft:'25%'}}>
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '15px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <div
                style={{  fontFamily: 'PlayfairDisplay', fontWeight: 'bold', fontSize:'18px', padding:'15px' }}>
              </div>
              {/* <form>
                    <div id="sign-in-button"></div>
                    <MDBInput
                      autoFocus
                      //label="Mobile Number "
                      placeholder="Mobile Number"
                      name="mobile"
                      type="int"
                      size="sm"
                      variant="outlined"
                      class="borderr"
                      style={{
                        backgroundColor: '#ffffff',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      onChange={(e) => {
                        setmobile(e.target.value)
                        if (
                          e.target.value.length < 10 ||
                          e.target.value.length > 10
                        ) {
                          setErrMobile('Please enter a valid number')
                        } else {
                          setErrMobile(false, 'valid number')
                        }
                      }}
                    />
                    <MDBTypography style={formval}>{ErrMobile}</MDBTypography>

                    <br />
                    <MDBBtn
                      type="button"
                      size="lg"
                      block
                      // style={{ backgroundColor: "#05818D" }}
                      onClick={(e) => onSignInSubmit(e)}
                      style={{
                        backgroundColor: '#FE3D0B',
                        display: !flag ? 'block' : 'none',
                      }}
                    >
                      Get otp
                    </MDBBtn>
                  </form>
              <form style={{ display: flag ? 'block' : 'none' }}
                  className="px-2"
                >
                  <MDBInput
                    // label="OTP "
                    placeholder="OTP"
                    name="OTP"
                    type="int"
                    // size="sm"
                    // variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#ffffff',
                      width: '100%',
                      height: '50px',
                      paddingLeft: '7px',
                    }}
                    required
                    onChange={(e) => {
                      setotp(e.target.value)
                    }}
                  />
                  <br />
                  <MDBBtn
                    variant="contained"
                    block
                    size="lg"
                    style={{ backgroundColor: '#FE3D0B' }}
                    type="submit"
                    onClick={(e) => onSubmitOTP(e)}
                  >
                    Submit
                  </MDBBtn></form> */}
                  <MDBRow size="6" sm="4">
                  <form>
                    {/* <MDBTypography>
                      <strong>
                        {' '}
                        Our Mission is to enrich people’s lives by creating the
                        largest EdTech company that allows individuals to
                        succeed in the real world.
                      </strong>
                    </MDBTypography> */}
                    <div id="sign-in-button"></div>
                    <MDBInput
                      autoFocus
                      //label="Mobile Number "
                      placeholder="Mobile Number"
                      name="mobile"
                      type="int"
                      variant="outlined"
                      class="borderr"
                      style={{
                        // backgroundColor: '#ffffff',
                        // width: '100%',
                        // height: '50px',
                        // paddingLeft: '7px',
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingBottom: '18px',paddingLeft: '7px',
                      }}
                      required
                      onChange={(e) => {
                        if(e.target.value.match("^\\d{10}$")!=null)  {
                          setmobile(e.target.value)
                          setErrMobile('')
                        } else {

                          setErrMobile('Please Enter a valid Mobile Number')
                        }
                      }}
                    />
                    <MDBTypography style={formval}>{ErrMobile}</MDBTypography>

                    <br />
                    <MDBBtn
                      type="button"
                      size="lg"
                      block
                      // style={{ backgroundColor: "#05818D" }}
                      onClick={(e) => onSignInSubmit(e)}
                      style={{
                        backgroundColor: '#FE3D0B',
                        display: !flag ? 'block' : 'none',
                        letterSpacing: '1.5px',
                       
                      }}
                    >
                      Get otp
                    </MDBBtn>
                  </form>
                </MDBRow>

                <MDBRow
                  style={{ display: flag ? 'block' : 'none' }}
                  className="px-2"
                >
                  <MDBInput
                    // label="OTP "
                    placeholder="OTP"
                    name="OTP"
                    type="int"
                    // size="sm"
                    // variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#ffffff',
                      width: '100%',
                      height: '50px',
                      paddingLeft: '7px',
                    }}
                    required
                    onChange={(e) => {
                      setotp(e.target.value)
                    }}
                  />
                  <br />
                  <MDBBtn
                    variant="contained"
                    block
                    size="lg"
                    style={{ backgroundColor: '#FE3D0B' ,letterSpacing: '1.5px',
                   }}
                    type="submit"
                    onClick={(e) => onSubmitOTP(e)}
                  >
                    Submit
                  </MDBBtn>
                </MDBRow>
              
            </MDBCol>
          </MDBModalBody>

    </MDBModalContent>
    </MDBModalDialog>
    </MDBModal>
    </div>
  )
}

export default Forgotpassword

import React from "react";
import Navbar from "./navbar.js";
import "./Course.css";
import Image from "../../assets/Background.png";
import Axios from "axios";
import { useState, useEffect } from "react";
import PDFViewer from "pdf-viewer-reactjs";
import { useLottie } from "lottie-react";
import { useLocation } from "react-router-dom";
import animationData from "../Lotties/SuccessLottie.json";
//import SuccessLottie from "../Lotties/SuccessLottie";
import coins from "../Lotties/coins.json";
import thumbsup from "../Lotties/thumbsup.json";
import Quiz from "./Quiz";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

toast.configure();
function Lessons(props) {
  const location = useLocation();
  const [CourseContent, _setCourseContent] = useState([]);

  const [percentage, setPercentage] = useState();
  // const [percentageview, setpercentageview] = useState();
  // const [Watchcount, setWatchcount] = useState();
  // const [Scorecount, setScorecount] = useState();
  // const [Contenttype, setContenttype] = useState();

  const [Coins, setCoins] = useState(false);
  // const [reloadQuiz, setReloadQuiz] = useState(false);
  const [quizQuest, _setQuizQuestions] = useState();
  const [showSuccessLottie, _setShowSuccessLottie] = useState(false);
  const [quizCompleted, _setQuizCompleted] = useState();
  const lottieRef = React.useRef(showSuccessLottie);

  const quizComplRef = React.useRef(quizCompleted);
  const quizQuestRef = React.useRef(quizQuest); //CourseContent
  const CourseContentRef = React.useRef(CourseContent); //
  const UserId = sessionStorage.getItem("userid");
  var divVideoPlayer = document.getElementById("videoPlayer");
  // var divDocViewer = document.getElementById("docViewer");
  // var divQuizViewer = document.getElementById("quizViewer");
  let course_ID = sessionStorage.getItem("courseId");
  let course_Title = sessionStorage.getItem("courseTitle");
 // console.log(CourseContent);
  // const vidEle = document.getElementById("videoCart");
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: thumbsup,

      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  //console.log(CourseContent);
  // const notify = () =>
  //   toast.success("You have successfully completed the topic", {
  //     toastId: "success1",
  //     autoClose: 4000,
  //   });
  const notify = () => {
    toast.success(
      <div>
        <Coin />
        {"You have successfully completed the topic"}
      </div>,
      {
        toastId: "success1",
        autoClose: 2000,
      }
    );
    setTimeout(navigate, 5000);
    // props.history.replace("/Course");
  };
  const navigate = () => {
    props.history.replace("/Course");
  };
  const questions = [
    {
      question: "Arrange the number -3/5, -7/10and -5/8 in ascending order.",
      answers: [
        "-5/8<-3/5<-7/10",
        "3/5<-7/10<-5/8",
        "7/10<-5/8<-3/5",
        "None of these",
      ],
      correctAnswerIndex: 2,
    },
  ];
  const setCourseContent = (data) => {
    CourseContentRef.current = data;
    _setCourseContent(data);
  };
  const setQuizQuestions = (data) => {
    quizQuestRef.current = data;
    _setQuizQuestions(data);
  };
  const setQuizCompleted = (data) => {
    quizComplRef.current = data;
    _setQuizCompleted(data);
  };

  const setShowSuccessLottie = (isVisible) => {
    let lDiv = document.getElementById("succ-lot");

    if (isVisible) {
      lDiv.style.zIndex = "10";
    } else {
      lDiv.style.zIndex = "-1";
    }
    lottieRef.current = isVisible;
    _setShowSuccessLottie(isVisible);
  };

  useEffect(() => {
    // setReloadQuiz(false);

    let cObj = sessionStorage.getItem("contentObj");
    let obj = JSON.parse(cObj);
    //console.log(obj);
    setPercentage(0);
    setShowSuccessLottie(false);
    //document.getElementById("videoPlayer");

    Axios.get(`/user/Content/${obj}/${UserId}`).then((response) => {
      // response.data.recordset.map((content) => {
      //   //console.log(content);
      //   content.PERCENTAGE_VIEWED =
      //     (content.CURRENT_PROGRESS / content.CONTENT_VIDEO_DURATION) * 100;
      //   if (content.CONTENT_PROGRESS_STATUS === 3) {
      //     content.CURRENT_PROGRESS = 0;
      //     content.PERCENTAGE_VIEWED = 100;
      //   }
      // });

      let contentEle = response.data.recordset[0];
      contentEle.PERCENTAGE_VIEWED =
        (contentEle.CURRENT_PROGRESS / contentEle.CONTENT_VIDEO_DURATION) * 100;
      if (contentEle.CONTENT_PROGRESS_STATUS === 3) {
        contentEle.CURRENT_PROGRESS = 0;
        contentEle.PERCENTAGE_VIEWED = 100;
      }
      setCourseContent(contentEle);
      if (contentEle.IS_FREE_PREVIEW === 1) {
        setShowSuccessLottie(false);
        if (contentEle.CONTENT_TYPE === 1) {
          // if (divDocViewer)
          // {
          //   document.getElementById("docViewer").style.display = "none";
          // }
          // if (divQuizViewer)
          // document.getElementById("quizViewer").style.display = "none";
          // if (divVideoPlayer)
          //   document.getElementById("videoCart").style.display = "block";
          // if (document.getElementById("videoCart").onplay)
          //   document
          //     .getElementById("videoCart")
          //     .removeEventListener("play", started);
          // if (document.getElementById("videoCart").onpause)
          //   document
          //     .getElementById("videoCart")
          //     .removeEventListener("pause", paused);
          // if (document.getElementById("videoCart").onended)
          //   document
          //     .getElementById("videoCart")
          //     .removeEventListener("ended", finished);

          // if(vidEle)
          // {
          let filepath = `/static/videos/${contentEle.CONTENT_VIDEO}#t=${contentEle.CURRENT_PROGRESS}`;
          // let filepath = `http://localhost:3860/static/videos/${contentEle.CONTENT_VIDEO}`;
          document.getElementById("videoCart").setAttribute("src", filepath);
          document
            .getElementById("videoCart")
            .setAttribute("preload", "metadata");

          // if (vidEle.onplay) vidEle.removeEventListener("play", started);
          // if (vidEle.onpause) vidEle.removeEventListener("pause", paused);
          // if (vidEle.onended) vidEle.removeEventListener("ended", finished);
          // vidEle.addEventListener("play", started, { passive: true });
          // vidEle.addEventListener("pause", paused, { passive: true });
          // vidEle.addEventListener("ended", finished, { passive: true });
          document
            .getElementById("videoCart")
            .addEventListener("play", started, { passive: true });
          document
            .getElementById("videoCart")
            .addEventListener("pause", paused, { passive: true });
          document
            .getElementById("videoCart")
            .addEventListener("ended", finished, { passive: true });
          document.getElementById("videoCart").style.display = "block";

          document.getElementById("videoCart").contentId =
            contentEle.CONTENT_ID;
          document.getElementById("videoCart").load();
          //vidEle.play()
          //}
        } else if (contentEle.CONTENT_TYPE === 2) {
          document.getElementById("videoCart").setAttribute("src", "");
          divVideoPlayer.style.display = "none";
          document.getElementById("quizViewer").style.display = "none";
          // document.getElementById("docViewer").style.display = "none";
        } else if (contentEle.CONTENT_TYPE === 3) {
          // <Quiz questions={quizQuest} parentCallback = {handleCallback}  />
          // setReloadQuiz(true);
          divVideoPlayer.style.display = "none";
          // document.getElementById("docViewer").style.display = "none";
          document.getElementById("quizViewer").style.display = "block";
          setQuizQuestions(questions);
          document.getElementById("videoCart").setAttribute("src", "");
        }
        //v.load();
      } else {
        document.getElementById("videoCart").setAttribute("src", "");
        divVideoPlayer.style.display = "none";
        document.getElementById("quizViewer").style.display = "none";
        // document.getElementById("docViewer").style.display = "none";
        setBasicModal(true);
      }
      //loadLesson(contentEle);
      //if(response.data.recordset)
    });
  }, []);

  const paused = (event) => {
    if (event.target.contentId) {
      //console.log(`paused--contentId- ${event.target.contentId}`);

      if (event.target.currentTime > 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          2,
          event.target.currentTime,
          event.target.duration
        );
      } else if (event.target.currentTime < 2.0) {
        UpdateProgress(
          UserId,
          event.target.contentId,
          1,
          event.target.currentTime,
          event.target.duration
        );
      }
    }
    document.getElementById("videoPlayer").style.display = "block";
  };
  const started = (event) => {
    if (event.target.contentId) {
      //console.log(`started--contentId- ${event.target.contentId}`);
    }
  };
  const finished = (event) => {
    setShowSuccessLottie(true);
    //console.log("notify");
    notify();

    <ToastContainer />;
    //}
    //toast.success("You have successfully completed the topic", { autoClose: 4000 });

    if (event.target.contentId) {
      if (event.target.currentTime === event.target.duration) {
        //console.log("end");
        UpdateProgress(
          UserId,
          event.target.contentId,
          3,
          event.target.currentTime,
          event.target.duration
        );
        CourseContent.WATCH_COUNT = CourseContent.WATCH_COUNT + 1;
      }
    }
    setTimeout(() => {
      setShowSuccessLottie(false);
    }, 2000);
  };
  const UpdateProgress = (
    userId,
    contentId,
    videoStatus,
    currentProgress,
    duration
  ) => {
    // console.log(
    //   `userId- ${userId}  contentId- ${contentId}  videoStatus-  ${videoStatus}  currentProgress${currentProgress}`
    // );
    Axios.post("/user/UpdateUserProgress", {
      USER_ID: userId,
      CONTENT_ID: contentId,
      VIDEO_STATUS: videoStatus,
      CURRENT_PROGRESS: currentProgress,
    }).then((response) => {
      if (response.status === 200) {
      } else {
       // console.log("Update progress error");
      }
    });
    let objCC = CourseContent;
    let coins = 0;
    if (videoStatus === 3) {
      coins = CourseContent.POINTS_TO_BE_AWARDED;
      setCoins(true);
    }
    if (objCC !== null && objCC !== undefined) {
      if (videoStatus === 3) {
        objCC.CURRENT_PROGRESS = 0;
        CourseContent.CURRENT_PROGRESS = 0;
      } else {
        objCC.CURRENT_PROGRESS = currentProgress;
        CourseContent.CURRENT_PROGRESS = currentProgress;
      }
      CourseContent.CONTENT_PROGRESS_STATUS = videoStatus;

      CourseContent.SCORE = coins;
      CourseContent.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      //console.log(JSON.stringify(CourseContent,null,4))
      objCC.CONTENT_PROGRESS_STATUS = videoStatus;

      objCC.SCORE = coins;
      objCC.PERCENTAGE_VIEWED = (currentProgress / duration) * 100;
      setCourseContent(objCC);
    }
  };
  const LessonCompletedLottie = () => {
    //animationData: success,
    const options = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "none",
      },
    };

    const { View } = useLottie(options);
    return View;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function loadLesson(contentObj) {
    const vidEle = document.getElementById("videoCart");
    if (contentObj.IS_FREE_PREVIEW === 1) {
      setShowSuccessLottie(false);
      /*setCurrentContentObj(contentObj);*/
      CourseContent(contentObj);

      //console.log(contentObj.CONTENT_TYPE);
      if (contentObj.CONTENT_TYPE === 1) {
        // if (divDocViewer)
        // {
        //   document.getElementById("docViewer").style.display = "none";
        // }
        if (document.getElementById("quizViewer"))
          document.getElementById("quizViewer").style.display = "none";
        if (divVideoPlayer) divVideoPlayer.style.display = "block";
        // if(vidEle)
        // {
        let filepath = `/static/videos/${contentObj.CONTENT_VIDEO}#t=${contentObj.CURRENT_PROGRESS}`;
        //filepath = encodeURI(filepath);
        //console.log(filepath);
        vidEle.setAttribute("src", filepath);
        vidEle.setAttribute("preload", "metadata");

        if (vidEle.onplay) vidEle.removeEventListener("play", started);
        if (vidEle.onpause) vidEle.removeEventListener("pause", paused);
        if (vidEle.onended) vidEle.removeEventListener("ended", finished);
        vidEle.addEventListener("play", started, { passive: true });
        vidEle.addEventListener("pause", paused, { passive: true });
        vidEle.addEventListener("ended", finished, { passive: true });
        vidEle.contentId = contentObj.CONTENT_ID;
        //vidEle.setAttribute('src',`http://localhost:3860/static/videos/introduction%20and%20properties.mp4`);
        vidEle.load();
        vidEle.style.display = "block";
        //vidEle.play()
        //}
      } else if (contentObj.CONTENT_TYPE === 2) {
        vidEle.setAttribute("src", "");
        divVideoPlayer.style.display = "none";
        document.getElementById("quizViewer").style.display = "none";
        // document.getElementById("docViewer").style.display = "none";
      } else if (contentObj.CONTENT_TYPE === 3) {
        // <Quiz questions={quizQuest} parentCallback = {handleCallback}  />
        // setReloadQuiz(true);
        divVideoPlayer.style.display = "none";
        // document.getElementById("docViewer").style.display = "none";
        document.getElementById("quizViewer").style.display = "block";
        setQuizQuestions(questions);
        vidEle.setAttribute("src", "");
      }
      //v.load();
    } else {
      vidEle.setAttribute("src", "");
      divVideoPlayer.style.display = "none";
      document.getElementById("quizViewer").style.display = "none";
      // document.getElementById("docViewer").style.display = "none";
      setBasicModal(true);
    }
  }
  const handleCallback = (quizComplete) => {
    setQuizCompleted(true);
    //divQuizViewer.style.display="none";
    //console.log(`quizCompleted- ${quizCompleted} currentContentObj.CONTENT_ID- ${currentContentObj.CONTENT_ID}`);
    UpdateProgress(UserId, CourseContent.CONTENT_ID, 3, 0);
  };

  return (
    <>
      <div id="succ-lot" className="lottie-wrapper">
        {showSuccessLottie && <LessonCompletedLottie />}
      </div>
      <div
        style={{
          fontFamily: "Maiandra",
        }}
      >
        <div>
          <Navbar props={Coins} />
        </div>

        <div style={{ margin: "15px" }}>
          <div>
            <MDBRow>
              <MDBCol size="9" className="float-start">
                <a href={`/Course`}>
                  <MDBIcon
                    size="lg"
                    className="float-start"
                    style={{
                      color: "#05818D",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      paddingRight: "10px",
                    }}
                    icon="arrow-circle-left"
                  />{" "}
                </a>
                <p
                  style={{
                    // textAlign: "center",

                    color: "#05818D",
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                  }}
                >
                  {course_Title}
                </p>
              </MDBCol>
              <MDBCol size="3" className="float-end">
                {/* { {statu} === 3 ? ( <></>) : (
                      <div>
                        <span style={{ fontSize: "18px", color: "gray" }}> { Math.round(CourseContent.PERCENTAGE_VIEWED) > 0 &&  CourseContent.PERCENTAGE_VIEWED !== undefined && Math.round(CourseContent.PERCENTAGE_VIEWED) < 100
                            ? Math.round(CourseContent.PERCENTAGE_VIEWED).toString() +
                              "%"
                            : ""}
                        </span>
                      </div>
                    )}  */}
                {CourseContent.WATCH_COUNT > 0 &&
                CourseContent.WATCH_COUNT !== undefined ? (
                  <MDBBadge className=" float-end" color="violet">
                    <MDBIcon
                      fas
                      icon="eye"
                      size="lg"
                      style={{
                      
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    />
                    <span style={{ fontSize: "18px", color: "gray" }}>
                      {CourseContent.WATCH_COUNT} Views
                    </span>
                  </MDBBadge>
                ) : (
                  <span></span>
                )}
                {CourseContent.SCORE > 0 &&
                CourseContent.SCORE !== undefined ? (
                  <MDBBadge className=" float-end" color="violet">
                    <MDBIcon
                      fas
                      icon="coins"
                      size="lg"
                      style={{
                        color: "orange",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                      }}
                    />
                    <span style={{ fontSize: "18px", color: "gray" }}>
                      {CourseContent.SCORE} Coins Earned
                    </span>
                  </MDBBadge>
                ) : (
                  <span></span>
                )}
              </MDBCol>
            </MDBRow>
          </div>
        </div>
        <div>
          <MDBRow style={{ height: "381px" }} className="gradient">
            <MDBCol
              size="6"
              style={{
                paddingLeft: "150px",
                paddingTop: "50px",
                color: "white",
                height: "381px",
              }}
            >
              <h3> {CourseContent.CONTENT_TITLE}</h3>
              <h5>{CourseContent.CONTENT_SHORT_DESCRIPTION}</h5>
            </MDBCol>
            <MDBCol
              id="videoPlayer"
              size="6"
              style={{
                height: "381px",
                width: "430px",
                display: "block",
              }}
            >
              {CourseContent.CONTENT_TYPE === 1 ? (
                <video
                  id="videoCart"
                  controls
                  autoPlay
                  src={`/static/videos/${CourseContent.CONTENT_VIDEO}`}
                  controlsList="nodownload"
                  style={{
                    height: "380px",
                    display: "block",
                    zIndex: "999",
                    // width: "400px",
                    // verticalAlign: "middle",
                    // padding: "1px",
                  }}
                ></video>
              ) : (
                <MDBRow id="quizViewer">
                  <div
                    id="startQuiz"
                    style={{
                      padding: "10%",
                      zIndex: "2",
                    }}
                  >
                    <Quiz
                      id="quizComp"
                      key={quizQuest}
                      questions={questions}
                      parentCallback={handleCallback}
                    />
                  </div>
                </MDBRow>
              )}
            </MDBCol>
          </MDBRow>
        </div>
      </div>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader style={{ backgroundColor: "orange" }}>
              <MDBModalTitle>Padho Bhado!!</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="warning"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Please Enroll to View this topic!!</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                style={{ backgroundColor: "#05818D" }}
                onClick={toggleShow}
              >
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}

export default Lessons;

import React, { useState, useEffect } from 'react'
import 'react-phone-number-input/style.css'
import firebase from './firebase'
import Image from './assets/hunar-ki-gali-banner.png'
import img from './assets/Group 1.png'
import logo from './assets/lst-logo.jpeg'
import signinlogo from './assets/signinlogo.png'
import drop from './assets/Sign.png'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBNavbarBrand,
  MDBIcon,
  MDBFooter,
  MDBDropdownDivider,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBCard,MDBCardImage,MDBCardTitle, MDBCardText, MDBCardOverlay
} from 'mdb-react-ui-kit'
import validator from 'validator'
import Axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const SignUp = (props) => {
  const formval = { fontWeight: 'bold', color: 'red', fontSize:'12px' }
  const [Password, setPassword] = useState('')
  const [mobile, setmobile] = useState('')
  const [ErrPassword, setErrPassword] = useState('')
  const [ErrConPassword, setErrConPassword] = useState('')
  const [ConPassword, setConPassword] = useState('')
  const [ErrMobile, setErrMobile] = useState('')
  const [flag, setFlag] = useState(false)
  const [otp, setotp] = useState('')
  const [result, setResult] = useState('')
  const [dynamicMessage, setDynamicMessage] = useState(''); 
  const [referralCode, setReferralCode] = useState('');
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundSize:'cover',
    backgroungRepeat: 'no-repeat',
    height: '100vh',
    opacity:'0.5'
  }
  useEffect( () => {
    GetDynamicContent();
  },[]);

  function GetDynamicContent() {
    var arr = ["We believe education and skills together can define your success", "Our ambition, your success", "Our motivation is a bright future for you. Yes you!", "Our ambition is that Education/Studies plus Skills combination reaches every doorstep. Each village, district and every corner of our country. We aspire that you build a capacity to live confidently in this 21st Century.", "PadhoBadho is committed to help ignite the flame of larger purpose in your life. We believe that co-existence of Education/Studies plus Skills will define a new charter of success by building a strong foundation.", "Did You Know that despite 15 years of rigorous education (12 years in schools and 3 years in college) nearly 54.1% of our youth is not job-ready which indicates a huge skills gap in education which needs to be addressed immediate."];
   //return arr[Math.floor(Math.random() * arr.length)];
   setDynamicMessage(arr[Math.floor(Math.random() * arr.length)]);
  }
  const checkmessage = () => {
    
    if (mobile !== null) {
      const payload = {
        MOBILE_NUMBER: mobile,
      }
      // Axios.post("http://localhost:9000/msg", payload, {
      Axios.post('/user/msg', payload, {
        'content-type': 'application/json',
        Accept: 'application/json',
      }).then((response) => {
        //console.log(response)
      })
    } else {
      //console.log('enter a valid number')
    }
  }
  const checkDetails = () => {
    if (mobile !== null && Password !== null) {
      sessionStorage.setItem('mobile', mobile)
      const payload = {
        PASSWORD: Password,
        MOBILE_NUMBER: mobile,
        REFERRAL_CODE: referralCode
      }
      // Axios.post("http://localhost:9000/signup", payload, {
       Axios.post('/user/signup', payload, {
        'content-type': 'application/json',
        Accept: 'application/json',
      })
        .then((response) => {
          if (response.status === 201) {
            // props.history.replace("/Signin");
            //console.log('success')
            props.history.replace('/Profile')
              //checkmessage()
            // navigate('/Signin', { replace: true });
          } else if (response.status === 401) {
            toast.error('Mobile Number Already Registered with Padhobadho, Please Login!', { tostId:'Existserr',
              autoClose: 10000,
              position: toast.POSITION.TOP_RIGHT,
            })
          } 
          else
          {
            console.log('technical error')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      console.log("Invalid details")
    }
  }
  const configureCaptcha = () => {
    //console.log('recaptcha')
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //console.log(response)
          setResult(response)
          setFlag(true)

          //onSignInSubmit()

          //console.log('Recaptcha verified')
        },
        defaultCountry: 'IN',
      },
    )
  }

  const onSignInSubmit = (e) => {
    if(ErrPassword === '' && ErrConPassword === '' && ErrMobile === '' && Password !== '' && ConPassword !== '' && mobile !== '')
    {
    e.preventDefault()  
    const payload = {
      MOBILE_NUMBER: mobile,
      REFERRAL_CODE: referralCode
    }
    Axios.post('/user/checkIfUserExists', payload, {
      'content-type': 'application/json',
      Accept: 'application/json',
    })
      .then((response) => {
        if (response.status === 201) {
          configureCaptcha()
          const phoneNumber = '+91' + mobile
          const appVerifier = window.recaptchaVerifier
        // console.log(appVerifier)
          firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult
              //console.log('OTP has been sent')
              // ...
            })
            .catch((error) => {
              // Error; SMS not sent
              // ...
              //console.log('SMS not sent')
            })

        } else if (response.status === 200) {
          toast.error('Mobile Number Already Registered with Padhobadho, Please Login!', { toastId:'RegisteredErr',
            autoClose: 10000,
            position: toast.POSITION.TOP_RIGHT,
          })
        } 
        else if (response.status === 202) {
          toast.error('Referral Code is Invalid, Please check with your Referrer!', { toastId:'InvRefCodeErr',
            autoClose: 10000,
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        else
        {
         // console.log('technical error')
        }
      })
      .catch((error) => {
        console.log('here.. '+ error)
      })

    
    }//end if
    else{
      toast.error('Please Enter All the details to Register', { toastId:'MandateRegErr',
        autoClose: 10000,
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }
  const reSendOTP = (e) => {
    const phoneNumber = '+91' + mobile
    const appVerifier = window.recaptchaVerifier
  // console.log(appVerifier)
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        //console.log('OTP has been sent')
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        //console.log(error);
       // console.log('SMS not sent')
      })
  }
  const onSubmitOTP = (e) => {
    e.preventDefault()

    const code = otp
    //console.log(code)
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user =  result.user
        //console.log(JSON.stringify(user))
        //console.log('User is verified')
       // console.log(user)
        if(user)
          checkDetails()
        
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        toast.error('Registration failed: ' + error, { toastId:'MandateOTPErr',
        autoClose: 10000,
        position: toast.POSITION.TOP_RIGHT,
      })
      })
  }
  return (
    <div>
      <div >
          <MDBRow size="12">
            <MDBCol size="4" className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor:'white'
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', height:'90px', marginLeft: '1rem' }}
                    alt=""
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="6">
              </MDBCol>
            <MDBCol size="2" >

            </MDBCol>
          </MDBRow>
        </div>
    <div style={bgimage} alt="backgroundimage">
      <div>
        <MDBRow>
          
         <MDBCol sm="4">
         {/* <MDBCard background='light' className='text-white'>
      <MDBCardOverlay>
        <MDBCardText>
        <div
                style={{  fontFamily: 'Playfair Display', fontWeight: 'bold', fontSize:'20px', padding:'5px' }}>
                  {dynamicMessage}
                </div>
        </MDBCardText>       
      </MDBCardOverlay>
    </MDBCard> */}
         
            {/* <Animation /> */}
            {/* <Animations /> */}

            {/* <Animationss  /> */}

            {/* <img
              src={drop}
              width="580px"
              height="580px"
              position="absolute"
              top="0"
              right="0"
              // className="pt-5 pb-3"
              alt="studentimage"
              // zIndex="999"
              className="float-end"
            ></img> */}
          </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
      </div>
      {/* <MDBFooter className="fixed">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <MDBCol
            size="10"
            className="text-center p-3"
            style={{
              fontSize: '10px',
              // backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            © 2022 Copyright:
            <a className="text-black" href="http://lmsdev.padhobadho.com/">
              PadhoBadho
            </a>
          </MDBCol>
          <MDBCol
            size="2"
            //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
          >
            <section
              className="float-end "
              style={{
                paddingTop: '5px',
                paddingRight: '10px',
                fontSize: '10px',
              }}
            >
              <a className="  btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="facebook-f" />
              </a>
              <a
                className=" btn-floating text-black "
                href="#!"
                role="button"
                size="10px"
              >
                <MDBIcon fab icon="instagram" />
              </a>

              <a className=" btn-floating text-black" href="#!" role="button">
                <MDBIcon fab icon="linkedin-in" />
              </a>
            </section>
          </MDBCol>
        </div>
        <MDBDropdownDivider></MDBDropdownDivider>
      </MDBFooter> */}
    </div>
          <MDBModal staticBackdrop show={true} setShow={true} nonInvasive={true} tabIndex='-1' >
            {/* <MDBModalDialog size='md-down' centered> */}
          <MDBModalDialog position="bottom-right" side>
            <MDBModalContent>
              
              <MDBModalBody>
            <MDBCol>
            <div>
            <div style={{paddingBottom:'15px', paddingLeft:'25%'}}>
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '15px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <div
                style={{  fontFamily: 'Playfair Display', fontWeight: 'bold', fontSize:'18px', padding:'15px', textAlign:'justify' }}>
                  {dynamicMessage}
                </div>
              
                <form style={{ display: !flag ? 'block' : 'none' }}>
                  
                  <div id="sign-in-button"></div>
                  <MDBInput
                    autoFocus
                    // label="Mobile Number "
                    name="mobile"
                    maxLength = "10"
                    type="int"
                    // size="sm"
                    placeholder="Mobile Number"
                    // variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#ffffff',
                      width: '100%',
                      height: '45px',
                      paddingLeft: '7px',
                      fontFamily: 'Poppins'
                    }}
                    required
                    onChange={(e) => {
                      if(e.target.value.match("^\\d{10}$") !== null)  {
                        setmobile(e.target.value)
                        setErrMobile('')
                      } else {
                        setErrMobile('Please Enter a Valid Mobile Number')
                      }
                    }}
                  />
                  <MDBTypography style={formval}>{ErrMobile}</MDBTypography>
                  {/* <MDBTypography style={{fontWeight: 'bold', fontSize:'13px'}}>Any 4 Numbers Eg., 1234</MDBTypography> */}
                  <MDBInput
                    // label="Password"
                    name="Pin"
                    type="password"
                    maxLength = "4"
                    placeholder="Pin (Any 4 Numbers Eg., 1234)"
                    variant="outlined"
                    class="borderr"
                    // size="sm"
                    style={{
                      backgroundColor: '#FFFFFF',
                      width: '100%',
                      height: '45px',
                      paddingLeft: '7px',
                      fontFamily: 'Poppins'
                    }}
                    required
                    onChange={(e) => {
                      if(e.target.value.match("^\\d{4}$") !== null)  {
                        setPassword(e.target.value)
                        setErrPassword('')
                      } else {
                        setErrPassword('Please enter 4 digit Pin')
                      }
                    }}
                  />
                  <MDBTypography style={formval}>{ErrPassword}</MDBTypography>
                  <MDBInput
                    //label="Confirm Password"
                    // size="sm"
                    name="Confirm Pin"
                    placeholder="Confirm Pin"
                    maxLength = "4"
                    type="password"
                    variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#FFFFFF',
                      width: '100%',
                      height: '45px',
                      paddingLeft: '7px',
                      fontFamily: 'Poppins'
                    }}
                    required
                    onChange={(e) => {
                      setConPassword(e.target.value)
                      if (Password === e.target.value) {
                        setErrConPassword('')
                      } else {
                        setErrConPassword("Pins do not match")
                      }
                      // if(Password === e.target.value)  {
                      //   setConPassword(e.target.value)
                      //   setErrConPassword('')
                      // } else {
                      //   setErrConPassword("Pins do not match")
                      // }
                    }}
                  />
                  <MDBTypography style={formval}> {ErrConPassword} </MDBTypography>
                  <MDBInput
                    // label="Mobile Number "
                    name="referralcode"
                    maxLength = "15"
                    
                    // size="sm"
                    placeholder="Referral Code"
                    // variant="outlined"
                    class="borderr"
                    style={{
                      backgroundColor: '#ffffff',
                      width: '100%',
                      height: '45px',
                      paddingLeft: '7px',
                      fontFamily: 'Poppins'
                    }}
                    required
                    onChange={(e) => {
                      setReferralCode(e.target.value)
                      
                    }}
                  />
                  <br />
                  <MDBBtn
                    type="button"
                    size="lg"
                    block
                    style={{
                      backgroundColor: '#FE3D0B',
                      borderRadius: '10px',
                      letterSpacing: '1.5px',
                      fontSize:'16px',
                      fontWeight:'bold',
                     
                    }}
                    onClick={(e) => onSignInSubmit(e)}
                  >
                    Register
                  </MDBBtn>
                  <MDBContainer className="pt-3  ">
                    <div
                      colorText="black"
                      className="text-center"
                      style={{ fontSize: '20px' }}
                    >
                      {' '}
                      Already Registered to My SkillForge ?{' '}
                      <a href="/Signin" className="link-dark">
                        Login Now
                      </a>
                    </div>
                  </MDBContainer>
                  <MDBContainer className=" pt-3"></MDBContainer>
                </form>
          

              <form style={{ display: flag ? 'block' : 'none' }}>
                <MDBContainer className="pt-5 pb-4 ">
                  <div
                    className="p-2"
                    style={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                    }}
                  >
                    Verification Step: Please enter the OTP shared to your
                    registered Mobile Number to Initiate the Account Setup
                    Process
                  </div>
                  <div
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: '25px',
                    }}
                  >
                    Enter OTP
                  </div>

                  <MDBInput
                    // label="OTP"
                    margin="normal"
                    type="int"
                    variant="outlined"
                    name="otp"
                    // size="sm"
                    className="borderr"
                    placeholder="OTP Number"
                    required
                    style={{
                      width: '100%',
                      height: '50px',
                      paddingLeft: '7px',
                    }}
                    onChange={(e) => {
                      setotp(e.target.value)
                    }}
                  />
                  <div
                        color="#FE3D0B"
                        className=" float-end"
                        style={{ color:"#FE3D0B", padding:"10px"}}
                      >
                        {' '}
                        
                        <a href="#" onClick={(e) => reSendOTP(e)}  color="#FE3D0B">
                          Resend OTP
                        </a>
                      </div>
                  <MDBBtn
                    variant="contained"
                    block
                    size="lg"
                    style={{
                      backgroundColor: '#FE3D0B',
                      borderRadius: '5px',
                      letterSpacing: '1.5px',
                      fontSize:'16px'
                    }}
                    type="submit"
                    onClick={(e) => onSubmitOTP(e)}
                  >
                    Submit
                  </MDBBtn>
                  
                </MDBContainer>
              </form>
            </div>
          </MDBCol>
              
             </MDBModalBody>

</MDBModalContent>
</MDBModalDialog>
</MDBModal>
</div>
  )
}

export default SignUp

import React from 'react'
import Navbar from './navbar.js'
import './Course.css'
import Image from '../../assets/Background.png'
import Accordion from 'react-bootstrap/Accordion'
import Axios from 'axios'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import calendar from '../Lotties/calendar.json'
import goldcoin from '../Lotties/goldcoin.json'
import enrollnow from '../Lotties/enrollnow.json'
import Imagecard from '../../assets/Imagecard1.png'
import Imagecards from '../../assets/Imagecard2.png'
import queryString from 'query-string'
import PDFViewer from 'pdf-viewer-reactjs'
import { useLottie } from 'lottie-react'
import { useLocation } from 'react-router-dom'
import animationData from '../Lotties/SuccessLottie.json'
//import SuccessLottie from "../Lotties/SuccessLottie";
import { withRouter } from 'react-router-dom'
import Quiz from './Quiz'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import RadialSeparators from './RadialSeparators'
import Test from './test.js'
import Footer from './footer'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBBadge,
  MDBAccordion,
  MDBAccordionItem,
  MDBIcon,
  MDBCardBody,
  MDBProgress,
  MDBProgressBar,
  MDBCard,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBFooter,
  MDBDropdownDivider,
} from 'mdb-react-ui-kit'
import { BorderColor } from '@mui/icons-material'
toast.configure()
function PreviewLesson(props) {
  const bgimage = {
    backgroundImage: `url(${Image})`,
    backgroundsize: 'cover',
  }
  const history = useHistory()
  const [CourseContents, setCourseContents] = useState([])
  const [course, setCourse] = useState([])
  const [courseTitle, setCourseTitle] = useState('')

  const [currentContentObj, _setCurrentContentObj] = useState(null)
  const [activeContentId, _setActiveContentId] = useState()
  const stateRef = React.useRef(currentContentObj)
  //const lottieRef = React.useRef(showSuccessLottie);
  const activeIdRef = React.useRef(activeContentId)
  const UserId = sessionStorage.getItem('userid')
  let course_ID = sessionStorage.getItem('courseId')
  sessionStorage.setItem('courseTitle', courseTitle)
  const GradeId = sessionStorage.getItem('grade')
  //console.log(CourseContents)
  const [Coins, setCoins] = useState(false)
  const [basicModal, setBasicModal] = useState(false)
  const toggleShow = () => setBasicModal(!basicModal)

  const Time = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: calendar,
      rendererSettings: {
        preserveAspectRatio: 'xMidyMid slice',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  const Coin = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: goldcoin,

      rendererSettings: {
        preserveAspectRatio: 'xMidyMid slice',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  const Enrollnow = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: enrollnow,

      rendererSettings: {
        preserveAspectRatio: 'xMidyMid slice',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  // const notify = () =>
  //   toast.success("You have successfully completed the topic", {
  //     toastId: "success1",
  //     autoClose: 4000,
  //   });
  const setCurrentContentObj = (data) => {
    stateRef.current = data
    _setCurrentContentObj(data)
  }
  //   const setShowSuccessLottie = (isVisible) => {
  //     let lDiv = document.getElementById("succ-lot");

  //     if (isVisible) {
  //       lDiv.style.zIndex = "10";
  //     } else {
  //       lDiv.style.zIndex = "-1";
  //     }
  //     lottieRef.current = isVisible;
  //     _setShowSuccessLottie(isVisible);
  //   };
  const setActiveContentId = (data) => {
    activeIdRef.current = data
    _setActiveContentId(data)
  }
  useEffect(
    (Coins) => {
      let cObj = sessionStorage.getItem('courseObj')
      let obj = JSON.parse(cObj)
      //console.log(obj)
      setCourse(obj)
      setCourseTitle(obj.COURSE_TITLE)
      //console.log(obj.COURSE_ID)
      //setShowSuccessLottie(false);
      //document.getElementById("videoPlayer").style.display = "none";
      Axios.get(`/user/PreviewContents/${obj.COURSE_ID}`).then((response) => {
        // response.data.recordset.map((content) => {
        //   content.PERCENTAGE_VIEWED =
        //     (content.CURRENT_PROGRESS / content.CONTENT_VIDEO_DURATION) * 100
        //   if (content.CONTENT_PROGRESS_STATUS === 3) {
        //     content.CURRENT_PROGRESS = 0
        //     content.PERCENTAGE_VIEWED = 100
        //   }
        // })
        //console.log(response.data.recordset)
        setCourseContents(response.data.recordset)
        //if(response.data.recordset)
        //   let activeContentObj = response.data.recordset.filter(
        //     (content) => content.CONTENT_PROGRESS_STATUS === 2
        //   )[0];

        //   if (activeContentObj !== null && activeContentObj !== undefined) {
        //     setActiveContentId(activeContentObj.PARENT_ID);
        //     //console.log(`pending-- ${JSON.stringify(activeContentObj.CONTENT_ID)}`);

        //   } else {
        //     activeContentObj = response.data.recordset.filter(
        //       (content) => content.PARENT_ID === 0
        //     )[0];
        //     //console.log(`parent-- ${activeContentObj.CONTENT_ID}`);
        //     setActiveContentId(activeContentObj.CONTENT_ID);
        //     //  console.log(`parent2-- ${activeContentObj}`);
        //   }
        //console.log(activeContentObj);
        //setCurrentContentObj(activeContentObj);
        //loadLesson(activeContentObj);
      })
    },
    [Coins],
  )

  //   function loadLesson(contentObj) {

  //     if (contentObj.IS_FREE_PREVIEW === 1) {

  //       setCurrentContentObj(contentObj);

  //     }
  //   }
  const notify = () => {
    toast(
      <div>
        <div
        //style={{ height: "100px", width: "100px" }}
        >
          <Enrollnow />
        </div>
        {'Please Enroll to View This Content'}
      </div>,
      {
        toastId: 'notify',
        autoClose: 2400,
        position: toast.POSITION.TOP_CENTER,
      },
    )
  }
  const navigateTo = (obj, path) => {
    //console.log(obj)
    //console.log(obj.IS_FREE_PREVIEW)
    if (obj.IS_FREE_PREVIEW === 0) {
      notify()
      // alert("Enroll");
    } else {
      sessionStorage.setItem('contentObj', JSON.stringify(obj.CONTENT_ID))

      history.push(path)
    }
  }
  return (
    <>
      <div style={bgimage} alt="backgroundimage">
        <div>
          <Navbar />
        </div>
        <div>
          <div style={{ margin: '15px' }}>
            <a href={(`/Student`, (props = { Coins }))}>
              <MDBIcon
                size="lg"
                style={{ color: '#1F594A', paddingLeft: '10px' }}
                icon="arrow-circle-left"
              />
            </a>
            <span
              width="30%"
              style={{
                fontSize: '30px',
                paddingLeft: '2rem',
                fontFamily: 'Playfair Display',
                fontWeight: 'bold',
                color: '#1F594A',
              }}
            >
              {course.GRADE !== null
                ? `Grade ${course.GRADE} - ${course.COURSE_TITLE}`
                : course.COURSE_TITLE}
              {/* Grade {GradeId} - {courseTitle} */}
            </span>
          </div>
        </div>
        <div>
          <MDBRow className="px-5">
            <MDBRow style={{ paddingBottom: '50px' }}>
              <MDBCol lg="7">
                <Accordion
                  id="lessonView"
                  key="lessonView"
                  defaultActiveKey={0}
                  style={{ fontFamily: 'Playfair Display' }}
                >
                  {CourseContents &&
                    CourseContents.filter(
                      (content) => content.PARENT_ID === 0,
                    ).map((content_det, mainIndex) => (
                      <Accordion.Item
                        style={{ padding: '0rem' }}
                        id={mainIndex + content_det.CONTENT_ID}
                        key={mainIndex + content_det.CONTENT_ID}
                        eventKey={mainIndex}
                      >
                        <Accordion.Header>
                          {content_det.CONTENT_TITLE}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul
                            className="list-group list-group-flush "
                            style={{ padding: '0px', margin: '0px' }}
                          >
                            <>
                              <MDBRow
                                style={{
                                  fontSize: '14px',
                                }}
                              >
                                {CourseContents.filter(
                                  (cContent) =>
                                    cContent.PARENT_ID ===
                                    content_det.CONTENT_ID,
                                ).map((obj, index, arr) => (
                                  <>
                                    {index % 6 === 0 ||
                                    index % 6 === 1 ||
                                    index % 6 === 5 ||
                                    index % 6 === 6 ? (
                                      <MDBCol
                                        size="6"
                                        className="wrapper"
                                        key={'col' + index}
                                      >
                                        {' '}
                                        <>
                                          {obj.IS_FREE_PREVIEW === 1 ? (
                                            <a
                                              href="#!"
                                              onClick={() =>
                                                navigateTo(obj, '/Contents')
                                              }
                                            >
                                              <div
                                                style={{
                                                  width: 90,
                                                  height: 90,
                                                }} >
                                                <CircularProgressbarWithChildren
                                                  value={
                                                    arr[index].PERCENTAGE_VIEWED
                                                  }
                                                  text={index + 1}
                                                  strokeWidth={10}
                                                  background
                                                  styles={buildStyles({
                                                    strokeLinecap: 'butt',
                                                    backgroundColor: '#1F594A',
                                                    textColor: '#fff',
                                                    pathColor: '#FE3D0B',
                                                    textSize: '35px',
                                                  })}
                                                >
                                                </CircularProgressbarWithChildren>
                                              </div>
                                            </a>
                                          ) : (
                                            <div
                                              style={{ width: 90, height: 90}} onClick = {() => notify()}
                                            >
                                              <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                text={index + 1}
                                                strokeWidth={10}
                                                background
                                                styles={buildStyles({
                                                  strokeLinecap: 'butt',
                                                  backgroundColor: '#FE3D0B',
                                                  textColor: '#fff',
                                                  pathColor: '#1F594A',
                                                  textSize: '35px',
                                                })}//#009641
                                              >
                                                <MDBIcon fas icon="lock" color="white" className="lessonIcon" size='3x' />
                                              </CircularProgressbarWithChildren>
                                            </div>
                                            
                                          )}
                                        </>
                                        <MDBCardBody
                                          style={{
                                            paddingBottom: '30px',
                                            fontSize: '18px',
                                            fontFamily: 'Playfair Display',
                                            fontWeight: 'true',
                                          }}
                                        >
                                          {arr[index].CONTENT_TYPE === 3
                                            ? `Quiz-${arr[index].CONTENT_TITLE}`
                                            : arr[index].CONTENT_TITLE}
                                        </MDBCardBody>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol size="12" className="wrapper">
                                        <>
                                          {obj.IS_FREE_PREVIEW === 1 ? (
                                            <a
                                              href="#!"
                                              onClick={() =>
                                                navigateTo(obj, '/Contents')
                                              }
                                            >
                                              <div
                                                style={{
                                                  width: 90,
                                                  height: 90,
                                                }} >
                                                <CircularProgressbarWithChildren
                                                  value={
                                                    arr[index].PERCENTAGE_VIEWED
                                                  }
                                                  text={index + 1}
                                                  strokeWidth={10}
                                                  background
                                                  styles={buildStyles({
                                                    strokeLinecap: 'butt',
                                                    backgroundColor: '#1F594A',
                                                    textColor: '#fff',
                                                    pathColor: '#FE3D0B',
                                                    textSize: '35px',
                                                  })}
                                                >
                                                </CircularProgressbarWithChildren>
                                              </div>
                                            </a>
                                          ) : (
                                            <div
                                              style={{ width: 90, height: 90}} onClick = {() => notify()}
                                            >
                                              <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                text={index + 1}
                                                strokeWidth={10}
                                                background
                                                styles={buildStyles({
                                                  strokeLinecap: 'butt',
                                                  backgroundColor: '#FE3D0B',
                                                  textColor: '#fff',
                                                  pathColor: '#1F594A',
                                                  textSize: '35px',
                                                })}//#009641
                                              >
                                                <MDBIcon fas icon="lock" color="white" className="lessonIcon" size='3x' />
                                              </CircularProgressbarWithChildren>
                                            </div>
                                            
                                          )}
                                        </>
                                        {/* <>
                                          {arr[index].PREDECESSORS_COMPLETED ===
                                          0 ? (
                                            <div
                                              style={{ width: 90, height: 90 }}
                                            >
                                              <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                text={index + 1}
                                                strokeWidth={10}
                                                background
                                                styles={buildStyles({
                                                  strokeLinecap: 'butt',
                                                  backgroundColor: '#fc8835',
                                                  textColor: '#fff',
                                                  pathColor: '#318c75',
                                                  textSize: '35px',
                                                })}
                                              ></CircularProgressbarWithChildren>
                                              {/* <CircularProgressbar
                                                  value= {arr[index].PERCENTAGE_VIEWED}
                                                  text= {(index+1)}                                                  
                                                  background
                                                  backgroundPadding={6}
                                                  styles={buildStyles({
                                                    backgroundColor: "#f97537",
                                                    textColor: "#fff",
                                                    pathColor: "#fff",
                                                    trailColor: "transparent",
                                                    textSize:'35px'
                                                  })}
                                                /> */}
                                              {/* <CircularProgressbarWithChildren
                                                value={
                                                  arr[index].PERCENTAGE_VIEWED
                                                }
                                                strokeWidth="6"
                                                styles={{
                                                  path: {
                                                    stroke: `rgba(62, 152, 199, ${
                                                      arr[index]
                                                        .PERCENTAGE_VIEWED / 100
                                                    })`,
                                                  },
                                                  trail: { stroke: '#d6d6d6' },
                                                }}
                                              >
                                                <MDBIcon
                                                  fas
                                                  icon={
                                                    arr[index].CONTENT_IMAGE
                                                  }
                                                  size="4x"
                                                  style={{ color: '#f97537' }}
                                                />
                                              </CircularProgressbarWithChildren> 
                                            </div>
                                          ) : (
                                            <a
                                              href="#!"
                                              onClick={() =>
                                                navigateTo(obj, '/Content')
                                              }
                                            >
                                              <div
                                                style={{
                                                  width: 90,
                                                  height: 90,
                                                }}
                                              >
                                                <CircularProgressbarWithChildren
                                                  value={
                                                    arr[index].PERCENTAGE_VIEWED
                                                  }
                                                  text={index + 1}
                                                  strokeWidth={5}
                                                  background
                                                  styles={buildStyles({
                                                    strokeLinecap: 'butt',
                                                    backgroundColor: '#318c75',
                                                    textColor: '#fff',
                                                    pathColor: '#fc8835',
                                                    textSize: '35px',
                                                  })}
                                                ></CircularProgressbarWithChildren>
                                              </div>
                                            </a>
                                          )}
                                        </> */}
                                        <MDBCardBody
                                          style={{
                                            paddingBottom: '30px',
                                            fontSize: '18px',
                                            fontFamily: 'Playfair Display',
                                            fontWeight: 'true',
                                          }}
                                        >
                                          {/* {arr[index].CONTENT_TITLE} */}
                                          {arr[index].CONTENT_TYPE === 3
                                            ? `Quiz-${arr[index].CONTENT_TITLE}`
                                            : arr[index].CONTENT_TITLE}
                                        </MDBCardBody>
                                      </MDBCol>
                                    )}
                                  </>
                                ))}
                              </MDBRow>
                            </>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        </div>
   
        {/* <MDBFooter className="absolute">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <MDBCol
              size="10"
              className="text-center p-3"
              style={{
                fontSize: '10px',
                // backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
            >
              © 2022 Copyright:
              <a className="text-black" href="http://lmsdev.padhobadho.com/">
                PadhoBadho
              </a>
            </MDBCol>
            <MDBCol
              size="2"
              //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
              <section
                className="float-end "
                style={{
                  paddingTop: '5px',
                  paddingRight: '10px',
                  fontSize: '10px',
                }}
              >
                <a
                  className="  btn-floating text-black"
                  href="#!"
                  role="button"
                >
                  <MDBIcon fab icon="facebook-f" />
                </a>
                <a
                  className=" btn-floating text-black "
                  href="#!"
                  role="button"
                  size="10px"
                >
                  <MDBIcon fab icon="instagram" />
                </a>

                <a className=" btn-floating text-black" href="#!" role="button">
                  <MDBIcon fab icon="linkedin-in" />
                </a>
              </section>
            </MDBCol>
          </div>
          <MDBDropdownDivider></MDBDropdownDivider>
        </MDBFooter> */}
      </div>
    </>
  )
}
export default PreviewLesson

import Axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLottie } from 'lottie-react'
import paymentsuccess from '../../src/Student/Lotties/green-tick-on-sucess.json'
import { lineHeight } from '@mui/system'
toast.configure()
// RAZORPAY_KEY_ID=rzp_test_kkPaDbdpErNfGV
// RAZORPAY_KEY_SECRET=KSi4DwboI4L0LufVwWJMK6l4
export default async function displayRazorpay(amount, username, email, mobile, userId, courseId) {
  const currency = 'INR'
  const payment_capture = 0
  // const data = await fetch(`/user/order/${amount}/${currency}/${payment_capture}`, {
  //   method: "POST",
  // }).then((t) => t.json());
  const data = await Axios.get(
    `/user/order/${amount}/${currency}/${payment_capture}`,
  )
  //console.log(data);
  //console.log('(amount, username,email,mobile): ' + amount, username,email,mobile)
  const SuccessLottie = () => {
    const options = {
      loop: true,
      autoplay: true,
      animationData: paymentsuccess,

      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    }

    const { View } = useLottie(options)
    return View
  }
  const options = {
    key: 'rzp_test_kkPaDbdpErNfGV',
    currency: currency,
    amount: amount * 100,
    name: 'My SkillForge Saksham Bano..',
    description: 'Paathyakram mein daakhila len..',
    image: '/static/Images/Final-Logo.png',
    order_id: data.id,
    handler: async function (response) {
      //console.log('response:  ' + JSON.stringify(response))
      try {
        const paymentId = response.razorpay_payment_id
        const orderId = response.razorpay_order_id
        const captureResponse = await Axios.post(
          `/user/capture/${paymentId}/${amount}/${currency}`,
          {},
        )
        //console.log('captureResponse:  ' + JSON.stringify(captureResponse))
        // const captureResponse = fetch(`/user/capture/${paymentId}/${amount}/${currency}`, {
        //     method: "POST",
        //   }).then((t) => t.json());//await Axios.post(url, {})
        toast(
          <div
            className="px-2"
            style={{
              height: '150px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#f9dcc5',
              backgroundSize: 'cover',
              borderRadius: '14px',
              alignItems: 'center',
              lineHeight: '20px',
            }}
            // className="rcorners"
          >
            <SuccessLottie />
            {'You have successfully enrolled to this topic!! Happy Learning!!!'}
          </div>,
          {
            toastId: 'success1',
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          },
        )
        //console.log('captureResponse:  ' + JSON.stringify(captureResponse.data))
      } catch (err) {
        console.log(err)
        toast(
          <div className="px-2"
          style={{
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            backgroundSize: 'cover',
            borderRadius: '14px',
            alignItems: 'center',
            lineHeight: '20px',
          }}>
            {/* {'Payment Failed!! Please Retry!!'} */}
            <SuccessLottie />
            {'You have successfully enrolled to this topic!! Happy Learning!!!'}
          </div>,
          {
            toastId: 'success1',
            autoClose: 3000,
            position: toast.POSITION.TOP_CENTER,
          },
        )
        const payload = {
          USER_ID: userId,
          COURSE_ID: courseId,
        }
        Axios.post('/user/Enrollfree', payload)
          .then((response) => {
            if (response.status === 200) {
              
            } else {
              //console.log('Technical Error')
            }
          })
          .catch((error) => {
            console.log(error)
          })
          window.location.reload(false);
      }
    },
    prefill: {
      name: username,
      email: email,
      contact: mobile,
    },
    theme: {
      color: '#FE3D0B',
    },
  }

  const paymentObject = new window.Razorpay(options)
  paymentObject.open()
}

import firebase from 'firebase/app';
import 'firebase/auth';
//old- obsolete
//const firebaseConfig = {
//  apiKey: "AIzaSyBzEof7Yz9BjxhZv7cNTkzYZsJsFmdtLSI",
//   authDomain: "ed2u-ce4da.firebaseapp.com",
//   projectId: "ed2u-ce4da",
//   storageBucket: "ed2u-ce4da.appspot.com",
//   messagingSenderId: "884852130240",
//   appId: "1:884852130240:web:bfa009418657873dcbc825"
// };

//prod environment
const firebaseConfig = {
  apiKey: "AIzaSyBjNvV_Xd1tWJ628G9TJMrUGF5DcZTYTg0",
  authDomain: "padho-badho.firebaseapp.com",
  databaseURL: "https://padho-badho-default-rtdb.firebaseio.com",
  projectId: "padho-badho",
  storageBucket: "padho-badho.appspot.com",
  messagingSenderId: "741401267615",
  appId: "1:741401267615:web:bce1ebf0894169589001aa",
  measurementId: "G-Z6EX071ZPD"
};

//testing environment
// const firebaseConfig = {
//   apiKey: "AIzaSyCPXVA8UZs2JAUdNq1VxrCJ22cwzQwJQSU",
//   authDomain: "padhobadho-testing.firebaseapp.com",
//   projectId: "padhobadho-testing",
//   storageBucket: "padhobadho-testing.appspot.com",
//   messagingSenderId: "602436520605",
//   appId: "1:602436520605:web:ad37347d4ecea889a22274",
//   measurementId: "G-HJ1X26XJL6"
// };
// Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  
export default firebase
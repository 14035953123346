import React from 'react';

function Marketplace() {
  return (
    <div className='products'>
      <h1>offers and course details</h1>
    </div>
  );
}

export default Marketplace;

const Svg = () => {   //Notice PacsalCase name
    return (
        <svg viewBox="0 0 210 297">
            <path
                className="polymorph"
                d="m 0.22287672,94.082764 c 0,0 17.63282128,-34.831303 110.42670328,-36.348656 63.66595,-1.041057 101.55017,-59.333835 101.55017,-59.333835 L 211.66521,298.51041 H 0.75741577 Z"
            />
        </svg>
    );
};

export default Svg
import React from 'react'
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBDropdownDivider,
} from 'mdb-react-ui-kit'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import Navbar from './navbar.js'
import { useState, useEffect } from 'react'
import Image from '../../assets/Background.png'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Student from '../Student'
function Description(props) {
  const [data, setData] = React.useState([])
  const [courseId, setCourseId] = useState()
  const [CourseTitle, setCourseTitle] = useState()
  const bgimage = {
    backgroundImage: `url(${Image})`,
    height: '100vh',
    backgroundsize: 'cover',
  }
  useEffect(() => {
    if (
      sessionStorage.getItem('userid') === null ||
      sessionStorage.getItem('userid') === undefined
    ) {
      window.location.href = '/'
    }
    let cObj = sessionStorage.getItem('courseObj')
    let obj = JSON.parse(cObj)
    setCourseId(obj.COURSE_ID)
    setCourseTitle(obj.COURSE_TITLE)
    // let param = queryString.parse(props.location.search);
    // setCourseId(param.courseId);
    Axios.get(`/user/Courses/${obj.COURSE_ID}`).then((response) => {
      setData(response.data.recordset)
      //console.log(response.data.recordset)
    })
  }, [])
  return (
    <div
    //  style={bgimage} alt="backgroundimage"
    >
      <div>
        <Navbar />
      </div>
      <MDBRow>
        <MDBCol size="9" className="float-start">
          <a href={`/Student`}>
            <MDBIcon
              size="lg"
              className="float-start"
              style={{
                color: '#1F594A',
                paddingLeft: '20px',
                paddingTop: '10px',
                paddingRight: '10px',
              }}
              icon="arrow-circle-left"
            />{' '}
          </a>
          <p
            style={{
              // textAlign: "center",
              fontFamily: 'Playfair Display',
              color: '#1F594A',
              fontSize: '18px',
              fontWeight: 'bold',
              paddingLeft: '40px',
              paddingTop: '5px',
            }}
          >
            {CourseTitle}
          </p>
        </MDBCol>
      </MDBRow>
      {data &&
        data.map((user) => (
          <MDBRow
            style={{
              height: '480px',
            }}
            className="gradient"
          >
            <MDBCol
              size="6"
              style={{ paddingLeft: '50px', paddingTop: '50px' }}
            >
              {/* <h1>{CourseTitle}</h1> */}
              <img
                width="500"
                height="320"
                src={`/static/Images/${user.COURSE_IMAGE}`}
                alt="..."
              ></img>
            </MDBCol>

            <MDBCol size="6" style={{ paddingTop: '50px' }}>
              <video
                id="videoCart"
                style={{
                  height: '310px',
                  width: '660px',
                  paddingLeft: '10px',
                  verticalAlign: 'middle',
                }}
                src={`/static/videos/${user.COURSE_VIDEO}`}
                controls
                controlsList="nofullscreen nodownload"
              ></video>
            </MDBCol>

            <div className="py-2">
              <MDBDropdownDivider></MDBDropdownDivider>
            </div>
            <MDBRow>
              <div style={{ fontFamily: 'Playfair Display' }}>
                {user.COURSE_DETAILS} To know more details, Please Check out the
                Video.
              </div>
            </MDBRow>
            <div className="py-2">
              <MDBDropdownDivider></MDBDropdownDivider>
            </div>
            <MDBRow className="pb-2 pt-4">
              <MDBCol size="6"></MDBCol>
              <MDBCol size="6">
                <MDBBtn
                  className="float-end"
                  type="button"
                  size="sm"
                  style={{
                    backgroundColor: '#1F594A',
                    Width: '60px',
                    letterSpacing: '1.5px',
                    paddingBottom: '2px',
                   
                  }}
                >
                  Enroll Now!!
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBRow>
        ))}
    </div>
  )
}
export default Description

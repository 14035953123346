import React from 'react'

import Axios from 'axios'
import validator from 'validator'
import { useEffect, useState } from 'react'
import Image from '../../assets/hunar-ki-gali-banner.png'
// import img from '../../assets/Group 1.png'
import img from '../../assets/profile.png'
import logo from '../../assets/lst-logo.jpeg'
import signinlogo from '../../assets/signinlogo.png'
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTypography,
  MDBSelect,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBNavbarBrand,
  MDBIcon,
  MDBFooter,
  MDBDropdownDivider,
} from 'mdb-react-ui-kit'
import { Select } from '@mui/material'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { responsiveProperty } from '@mui/material/styles/cssUtils'
toast.configure()
function Profile(props) {
  const formval = { fontWeight: 'bold', color: 'red', fontSize:'12px' }
  const [data, setData] = useState([])
  const [City, setCity] = useState('')
  const [Statee, setStatee] = useState('')
  const [Email, setEmail] = useState('')
  const [Gender, setGender] = useState('0')
  const [Grade, setGrade] = useState('0')
  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [ErrGender, setErrGender] = useState('')
  const [ErrGrade, setErrGrade] = useState('')
  const [ErrFirstname, setErrFirstname] = useState('')
  const [ErrLastname, setErrLastname] = useState('')
  const [mobile, setmobile] = useState('')
  const [basicModal, setBasicModal] = useState(false)
  const toggleShow = () => setBasicModal(!basicModal)
  const [alertMessage, _setAlertMessage] = useState('')
  const alertMessageRef = React.useRef(alertMessage)
  const [dynamicMessage, setDynamicMessage] = useState(''); 
  const setAlertMessage = (msg) => {
    alertMessageRef.current = msg
    _setAlertMessage(msg)
  }

  const alertType = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
  }
  const bgimage = {    backgroundImage: `url(${Image})`,
  backgroundSize:'cover',
  backgroungRepeat: 'no-repeat',
  height: '100vh',
  opacity:'0.5'
  }
  //console.log(Email)
  const Qualification = [
    {
      Value:'0',
      Text:'Select',
    },
    {
      Value: 'M',
      Text: 'Male',
    },
    {
      Value: 'F',
      Text: 'Female',
    },
    {
      Value: 'P',
      Text: 'Prefer Not to Say',
    },
  ]
  const state = [
    {
      Value: 'Andhra Pradesh',
      Text: 'Andhra Pradesh',
    },
    {
      Value: 'Arunachal Pradesh',
      Text: 'Arunachal Pradesh',
    },
    {
      Value: 'Assam',
      Text: 'Assam',
    },
    {
      Value: 'Bihar',
      Text: 'Bihar',
    },

    {
      Value: 'Chhattisgarh',
      Text: 'Chhattisgarh',
    },
    {
      Value: 'Goa',
      Text: 'Goa',
    },

    {
      Value: 'Gujarat',
      Text: 'Gujarat',
    },

    {
      Value: 'Haryana',
      Text: 'Haryana',
    },

    {
      Value: 'Himachal Pradesh',
      Text: 'Himachal Pradesh',
    },
    {
      Value: 'Jharkhand',
      Text: 'Jharkhand',
    },
    {
      Value: 'Karnataka',
      Text: 'Karnataka',
    },
    {
      Value: 'Kerala',
      Text: 'Kerala',
    },

    {
      Value: ' Madhya Pradesh',
      Text: 'Madhya Pradesh',
    },
    {
      Value: 'Maharashtra',
      Text: 'Maharashtra',
    },
    {
      Value: ' Manipur',
      Text: 'Manipur',
    },
    {
      Value: 'Meghalaya',
      Text: 'Meghalaya',
    },
    {
      Value: 'Mizoram',
      Text: 'Mizoram',
    },
    {
      Value: ' Nagaland',
      Text: 'Nagaland',
    },
    {
      Value: 'Odisha',
      Text: 'Odisha',
    },
    {
      Value: 'Punjab',
      Text: 'Punjab',
    },
    {
      Value: ' Rajasthan',
      Text: 'Rajasthan',
    },
    {
      Value: 'Sikkim',
      Text: 'Sikkim',
    },
    {
      Value: 'Tamil Nadu',
      Text: 'Tamil Nadu',
    },
    {
      Value: 'Telangana',
      Text: 'Telangana',
    },
    {
      Value: ' Tripura',
      Text: 'Tripura',
    },
    {
      Value: 'Uttar Pradesh',
      Text: 'Uttar Pradesh',
    },
    {
      Value: 'Uttarakhand',
      Text: 'Uttarakhand',
    },
    {
      Value: 'West Bengal',
      Text: 'West Bengal',
    },
    {
      Value: ' Andaman and Nicobar Islands (UT)',
      Text: 'Andaman and Nicobar Islands (UT)',
    },
    {
      Value: 'Chandigarh (UT)',
      Text: 'Chandigarh (UT)',
    },
    {
      Value: 'Dadra & Nagar Haveli and Daman & Diu (UT)',
      Text: 'Dadra & Nagar Haveli and Daman & Diu (UT)',
    },
    {
      Value: 'Delhi (UT)',
      Text: 'Delhi (UT)',
    },
    {
      Value: 'Jammu and Kashmir (UT)',
      Text: 'Jammu and Kashmir (UT)',
    },
    {
      Value: 'Lakshadweep (UT)',
      Text: 'Lakshadweep (UT)',
    },
    {
      Value: 'Puducherry (UT)',
      Text: 'Puducherry (UT)',
    },
    {
      Value: 'Ladakh (UT)',
      Text: 'Ladakh (UT)',
    },
  ]
  const grade = [
    {
      Value: '0',
      Text: 'Select',
    },
    {
      Value: '6',
      Text: 'Grade 6',
    },
    {
      Value: '7',
      Text: 'Grade 7',
    },

    {
      Value: '8',
      Text: 'Grade 8',
    },

    {
      Value: '9',
      Text: 'Grade 9',
    },
    {
      Value: '10',
      Text: 'Grade 10',
    },
    {
      Value: '11',
      Text: 'Grade 11',
    },
    {
      Value: '12',
      Text: 'Grade 12',
    },
  ]
  function GetDynamicContent() {
    var arr = ["We believe education and skills together can define your success", "Our ambition, your success", "Our motivation is a bright future for you. Yes you!", "Our ambition is that Education/Studies plus Skills combination reaches every doorstep. Each village, district and every corner of our country. We aspire that you build a capacity to live confidently in this 21st Century.", "PadhoBadho is committed to help ignite the flame of larger purpose in your life. We believe that co-existence of Education/Studies plus Skills will define a new charter of success by building a strong foundation.", "Did You Know that despite 15 years of rigorous education (12 years in schools and 3 years in college) nearly 54.1% of our youth is not job-ready which indicates a huge skills gap in education which needs to be addressed immediate."];
    setDynamicMessage(arr[Math.floor(Math.random() * arr.length)]);
}

useEffect( () => {

  if( sessionStorage.getItem('mobile') === null || sessionStorage.getItem('mobile') === undefined)
  {
    sessionStorage.clear();
    props.history.replace('/');
  }
  GetDynamicContent();
},[]);

  const checkDetails = (e) => {
    const Mobile = sessionStorage.getItem('mobile')
    if(ErrFirstname ==='' && ErrLastname === '' && ErrGrade === '' && ErrGender === '' && firstname !== '' && lastname !== '' && Grade !== '0' && Gender !== '0')
  {
    Axios.post('/user/StudentData', {
      // Axios.post("http://localhost:9000/StudentData", payload, {
      EMAIL: Email,
      FIRST_NAME: firstname,
      LAST_NAME: lastname,
      Mobile: Mobile,
      City: City,
      Gender: Gender,
      Grade: Grade,
      State: Statee,
    }).then((response) => {
      //console.log(response)
      if (response.status === 200) {
        toast.success(
          'Account Setup Successfully Completed. Welcome to My SkillForge Community. Happy Learning!',
          { autoClose: 10000 },
        )
        sessionStorage.setItem('userid', response.data.result.message)
        sessionStorage.setItem('score', 0)
        sessionStorage.setItem('grade', Grade)
        sessionStorage.setItem('email', Email)
        sessionStorage.setItem('mobile', Mobile)
        sessionStorage.setItem('username', firstname)
        //props.history.replace('/Signin') //0109
        props.history.replace('/Student')
        // setBasicModal(true);         //e.preventdefault();
      } else {
        // setAlertMessage("Technical Glitch. Please Retry");
        //console.log('technical error')
      }

    })
  }
  else{
    toast.error('Please fill all the details', { toastId:'mandFieldserr',
      autoClose: 5000,
      position: toast.POSITION.TOP_RIGHT,
    })
  }
  }

  // useEffect(() => {
  //   const Mobile = sessionStorage.getItem("mobile");
  //   Axios.get(`http://localhost:9000/Student/${Mobile}`).then((response) => {
  //     setData(response.data.recordset);
  //     console.log(data.FIRST_NAME);
  //   });
  // }, []);

  return (
    <div>
      <div >
          <MDBRow size="12">
            <MDBCol className="float-start">
              <a href="/Signin">
                <MDBNavbarBrand
                  style={{
                    paddingLeft: '10px',
                    paddingTop: '10px',
                    paddingRight: '10px',
                    backgroundColor:'white'
                  }}
                >
                  <img
                    src={logo}
                    style={{ width: '90px', height:'90px', marginLeft: '1rem' }}
                    alt=""
                  />
                </MDBNavbarBrand>
              </a>
            </MDBCol>
            <MDBCol size="2" >

            </MDBCol>
          </MDBRow>
        </div>
      <div style={bgimage} alt="backgroundimage">
        <div>
          <MDBRow style={{ paddingBottom: '10px' }}>

            
            <MDBCol sm="7">
              {/* <img
                src={img}
                width="580px"
                height="560px"
                //className="pt-5 pb-5"
                alt="studentimage"
                className="float-end"
              ></img> */}
            </MDBCol>
            <MDBCol size="6" sm="2"></MDBCol>
          </MDBRow>
        </div>
        {/* <MDBFooter className="fixed">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <MDBCol
              size="10"
              className="text-center p-3"
              style={{
                fontSize: '10px',
                // backgroundColor: 'rgba(0, 0, 0, 0.2)',
              }}
            >
              © 2022 Copyright:
              <a className="text-black" href="http://lmsdev.padhobadho.com/">
                PadhoBadho
              </a>
            </MDBCol>
            <MDBCol
              size="2"
              //  style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
              <section
                className="float-end "
                style={{
                  paddingTop: '5px',
                  paddingRight: '10px',
                  fontSize: '10px',
                }}
              >
                <a
                  className="  btn-floating text-black"
                  href="#!"
                  role="button"
                >
                  <MDBIcon fab icon="facebook-f" />
                </a>
                <a
                  className=" btn-floating text-black "
                  href="#!"
                  role="button"
                  size="10px"
                >
                  <MDBIcon fab icon="instagram" />
                </a>

                <a className=" btn-floating text-black" href="#!" role="button">
                  <MDBIcon fab icon="linkedin-in" />
                </a>
              </section>
            </MDBCol>
          </div>
          <MDBDropdownDivider></MDBDropdownDivider>
        </MDBFooter> */}
      </div>
      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Padho Bhado!!</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Account Setup Successfully Completed. Welcome to My SkillForge
              Community. Happy Learning!
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="teal" onClick={toggleShow}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal staticBackdrop show={true} setShow={true} tabIndex='-1' >
      <MDBModalDialog size='md-down' centered>
        <MDBModalContent>
          
          <MDBModalBody>
          <MDBCol>
                <div style={{paddingBottom:'15px', paddingLeft:'25%'}}>
                  <img
                    style={{
                      //width: '90px',
                      height: '70px',
                      paddingBottom: '15px',
                    }}
                    src={signinlogo}
                    alt="logo"
                  ></img>
                </div>
                <div
                style={{  fontFamily: 'PlayfairDisplay', fontWeight: 'bold', fontSize:'18px', padding:'15px' }}>
                {dynamicMessage}
              </div>
              <form className="pb-5">
                    <MDBInput
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      autoFocus
                      // size="lg"
                      required
                      // variant="outlined"
                      // label="First Name"
                      Class="borderr"
                      placeholder="First Name"
                      // name="FirstName"
                      // value={user.FIRST_NAME}
                      onChange={(event) => {
                        if(event.target.value === '')
                          {setErrFirstname('Firstname is Mandatory');}
                        else
                          {
                            setfirstname(event.target.value);
                            setErrFirstname('');
                          }
                      }
                       
                      }
                    /><MDBTypography style={formval}>{ErrFirstname}</MDBTypography>
                    <div className="py-1"></div>
                    <MDBInput
                      // label="Last Name"
                      // name="LastName"
                      // variant="outlined"
                      Class="borderr"
                      placeholder="Last Name"
                      // size="lg"
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      // value={user.LAST_NAME}
                      onChange={(event) => {
                        if(event.target.value === '')
                          setErrLastname('Lastname is Mandatory');
                        else
                          {
                            setlastname(event.target.value);
                            setErrLastname('');
                          }
                      }}
                    /><MDBTypography style={formval}>{ErrLastname}</MDBTypography>
                    {/* <div className="py-1"></div>
                    <MDBInput
                      // label="Email"
                      // name="Email"
                      // variant="outlined"
                      // size="sm"
                      Class="borderr"
                      placeholder="Email"
                      // size="lg"
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      // value={user.EMAIL}
                      onChange={(event) => {
                        setEmail(event.target.value)
                      }}
                    /> */}
                    {/* <div className="py-1"></div>
                    <MDBInput
                      // label="City"
                      // name="City"
                      // variant="outlined"
                      // size="sm"
                      Class="borderr"
                      placeholder="City"
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        height: '50px',
                        paddingLeft: '7px',
                      }}
                      required
                      // value={user.EMAIL}
                      onChange={(event) => {
                        setCity(event.target.value)
                      }}
                    />
                    <div className="py-1"></div>
                    <select
                      className="browser-default custom-select"
                      style={{
                        backgroundColor: '#ffffff',
                        // variant: 'outlined',
                        width: '100%',
                        height: '50px',
                        borderRadius: '10px',
                        borderColor: '#B2BEB5',
                        fontSize: '1rem',
                        color: '#757575',
                        paddingLeft: '7px',
                      }}
                      onChange={(event) => {
                        setStatee(event.target.value)
                      }}
                    >
                      {state.map((option) => (
                        <option value={option.Value}> {option.Text}</option>
                      ))}
                    </select> */}
                    <div className="py-1"></div>

<select
  className="browser-default custom-select"
  style={{
    backgroundColor: '#ffffff',
    // variant: 'outlined',
    width: '100%',
    height: '50px',
    borderRadius: '10px',
    borderColor: '#B2BEB5',
    // fontSize: '.775rem',
    fontSize: '1rem',
    color: '#757575',
    paddingLeft: '7px',
  }}
  required
  onChange={(event) => {
    if(event.target.value === 0)
    {
      setErrGrade('Grade is Mandatory')
      setGrade('0');
    }
    else
    {
      setGrade(event.target.value)
      setErrGrade('')
  }}}
>
  {grade.map((option) => (
    <option value={option.Value}> {option.Text}</option>
  ))}
</select><MDBTypography style={formval}>{ErrGrade}</MDBTypography>
                    <div className="py-1"></div>

                    <select
                      className="browser-default custom-select"
                      style={{
                        backgroundColor: '#ffffff',
                        // variant: 'outlined',
                        width: '100%',
                        height: '50px',
                        borderRadius: '10px',
                        borderColor: '#B2BEB5',
                        fontSize: '1rem',
                        color: '#757575',
                        paddingLeft: '7px',
                      }}
                      block
                      required 
                      onChange={(event) => {
                        if(event.target.value === '0')
                        {
                            setErrGender('Gender is Mandatory');
                            setGender('0')
                        }
                        else{
                          setGender(event.target.value);
                          setErrGender('');
                      }
                      }}
                    >
                      {Qualification.map((option) => (
                        <option value={option.Value}> {option.Text}</option>
                      ))}
                    </select><MDBTypography style={formval}>{ErrGender}</MDBTypography>
                    
                    <div className="pb-4"></div>
                    <MDBBtn
                      type="button"
                      size="lg"
                      block
                      style={{
                        backgroundColor: '#FE3D0B',
                        //borderRadius: '2px',
                        letterSpacing: '1.5px',
                       
                      }}
                      onClick={(e) => checkDetails()}
                    >
                      Submit
                    </MDBBtn>
                  </form>
                  </MDBCol>

          </MDBModalBody>

        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
    
    </div>
  )
}

export default Profile

import React from 'react';

function Leaderboard() {
  return (
    <div className='reports'>
      <h1>leader board of Students</h1>
    </div>
  );
}

export default Leaderboard;

import React from 'react'
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Divider,
  Button,
  Link,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import Axios from 'axios'
import Rating from '@material-ui/lab/Rating'
import ReadMoreAndLess from 'react-read-more-less'

function AllCourses() {
  const [data, setData] = useState([])
  const [chapter, setChapter] = React.useState([])

  var queue = new Array()
  useEffect(() => {
    if (
      sessionStorage.getItem('userid') === null ||
      sessionStorage.getItem('userid') === undefined
    ) {
      window.location.href = '/'
    }
    Axios.get('/user/CourseList/').then((response) => {
      setData(response.data.recordset)
      //console.log("hello hello");
      for (let i = 0; i <= response.data.recordset.length; i++) {
       // console.log(response.data.recordset[i].COURSE_TITLE)
        queue.push(response.data.recordset[i].COURSE_TITLE)
        setChapter(queue)
      }
    })
  }, [])

  const dat = chapter.map((list) => {
    //console.log(list)
  })

  // const testing = () => {
  //   console.log("hello");
  //   props.history.replace("/Description");
  // };

  return (
    <div>
      <h1>Courses</h1>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} sm={6} xl={3} xs={12}>
            <Card>
              <CardMedia
                component="img"
                height="190"
                width="190"
                image="https://img.freepik.com/free-vector/abstract-wireframe-digital-human-female-face-streaming-matrix-digital-binary-code-background-ai-artificial-intelligence-concept_127544-849.jpg?size=626&ext=jpg"
                alt="green iguana"
              />
              <Link href="/Description">
                <div>
                  <Button variant="text">COURSE_TITLE </Button>
                </div>
              </Link>
              <ReadMoreAndLess
                className="read-more-content"
                charLimit={10}
                readMoreText="Read more"
                readLessText="Read less"
              >
                Artificial intelligence (AI) is the ability of a computer or a
                robot controlled by a computer to do tasks that are usually done
                by humans because...
              </ReadMoreAndLess>
              <Divider />
              <CardActions>
                <Grid container>
                  <Rating value={4} />
                  <Typography style={{ paddingLeft: 140 }}>₹999</Typography>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        {dat}
      </Container>
    </div>
  )
}

export default AllCourses
